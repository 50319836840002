import { Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { p_types } from "../Payments/SendMoney/Recipients";
import credit from "../../assets/icons/credit.png";
import debit from "../../assets/icons/debit.png";
import download from "../../assets/icons/file_download.png";
import pdfIcon from "../../assets/icons/pdf_file_icon.png";
import eye from "../Payments/SendMoney/assets/eye.png";
import { currencyMapper } from "../../config/helper";
import { AccountDisplay } from "./AccountCard";
import usePayAxios from "../../config/useAxios";
import { gridApis } from "../../config/GridApis";
import { amountFormat, getFileExtension } from "../../utils";
import { downloadFileDirectly } from "../Payments/SendMoney/PaymentReview";
import { app_loading } from "../../actions/auth";
import { APP_BASE_URL } from "../../config/Axios";
import { useDispatch } from "react-redux";
import EmailInput from "../Payments/SendMoney/EmailInput";

const TxnDetailModal = ({ open, onClose, txnDetail: data = {} }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("details");
  const [emailModal, setEmailModal] = useState(false);

  const [isPreviewOpen, setIsPreviewOpen] = useState({
    open: false,
    url: "",
    fileType: "",
  });
  const { onCall, data: attach } = usePayAxios({
    api: gridApis.fetchTransactionAttachments,
    method: "get",
  });
  useEffect(() => {
    if (data.fintech_payment_id)
      onCall({
        params: {
          fintech_payment_id: data.fintech_payment_id,
        },
      });
  }, [data]);

  const getAddress = () => {
    const {
      counterparty_address_city,
      counterparty_address_country_code,
      counterparty_address_state,
      counterparty_address_street1,
      counterparty_address_street2,
      counterparty_address_zipcode,
    } = data;
    if (data?.beneficiary_details?.shipping_addresses?.length)
      return (
        <span>
          {
            <>
              {data?.beneficiary_details?.shipping_addresses?.[0]?.address}
              {", "}
              {data?.beneficiary_details?.shipping_addresses?.[0]?.address_two}
              <br />
            </>
          }
          {data?.beneficiary_details?.shipping_addresses?.[0]?.city && (
            <>
              {data?.beneficiary_details?.shipping_addresses?.[0]?.city}
              <br />
            </>
          )}
          {data?.beneficiary_details?.shipping_addresses?.[0]?.state && (
            <>
              {data?.beneficiary_details?.shipping_addresses?.[0]?.state}
              <br />
            </>
          )}
          {data?.beneficiary_details?.shipping_addresses?.[0]?.country && (
            <>
              {data?.beneficiary_details?.shipping_addresses?.[0]?.country}
              <br />
            </>
          )}
          {data?.beneficiary_details?.shipping_addresses?.[0]?.zipcode && (
            <>{data?.beneficiary_details?.shipping_addresses?.[0]?.zipcode}</>
          )}
        </span>
      );
    else
      return (
        <span>
          {counterparty_address_street1 && (
            <>
              {counterparty_address_street1}
              {counterparty_address_street2 &&
                `, ${counterparty_address_street2}`}
              <br />
            </>
          )}
          {counterparty_address_city && (
            <>
              {counterparty_address_city}
              <br />
            </>
          )}
          {counterparty_address_state && (
            <>
              {counterparty_address_state}
              <br />
            </>
          )}
          {counterparty_address_zipcode && (
            <>
              {counterparty_address_zipcode}
              <br />
            </>
          )}
          {counterparty_address_country_code && (
            <>
              {counterparty_address_country_code}
              <br />
            </>
          )}
        </span>
      );
  };

  return (
    <>
      <Modal
        visible={isPreviewOpen?.open}
        onCancel={() => {
          setIsPreviewOpen({
            open: false,
            url: "",
            fileType: "",
          });
        }}
        footer={null}
        width="80%"
        centered
        className="preview_section_modal"
      >
        {isPreviewOpen?.fileType == "pdf" ? (
          <div style={{ width: "100%", height: "100vh" }}>
            <iframe
              src={isPreviewOpen?.url}
              allow="fullscreen"
              style={{ width: "100%", height: "100%", margin: "auto" }}
              title="description"
            ></iframe>
          </div>
        ) : (
          <div
            style={{ width: "100%", height: "100vh" }}
            className="d-flex-center"
          >
            <img
              src={isPreviewOpen?.url}
              alt="preview image"
              style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
            />
          </div>
        )}
      </Modal>
      {emailModal && (
        <Modal
          visible={emailModal}
          title="Send Payment Receipt"
          footer={[]}
          onCancel={() => {
            setEmailModal(false);
          }}
        >
          <EmailInput
            mainData={{ ...data, id: data?.id }}
            name={data?.counterparty_name}
            address={``}
            bank_location={``}
            bank_name={``}
            routing_code={
              data?.routing_code || data?.ifsc_code || data?.swift_code || ""
            }
            account_number={data?.account_number}
            cb={() => {
              setEmailModal(false);
            }}
            txn={true}
          />
        </Modal>
      )}

      <Modal
        className="right-alinged-modal alerts txn-dt"
        title={
          <label
            style={{
              color: "#363636",
              paddingBottom: 10,
              fontSize: 21,
            }}
          >
            Transaction Details
          </label>
        }
        visible={open}
        onCancel={onClose}
      >
        {(data?.status?.toLowerCase() === "processed" ||
          data?.status?.toLowerCase() === "success") && (
          <div className="btns-sec">
            <button
              className="share-btn"
              onClick={() => {
                const obj = {};
                obj.payment_id = data?.fintech_payment_id;
                const searchParams = new URLSearchParams(obj);
                dispatch(app_loading(true));
                downloadFileDirectly(
                  APP_BASE_URL +
                    gridApis.generateReceiptPdf +
                    "?" +
                    searchParams?.toString(),
                  () => {
                    dispatch(app_loading(false));
                  }
                );
              }}
            >
              Download receipt
            </button>
            <button
              className="mlHalf share-btn"
              onClick={() => {
                setEmailModal(true);
              }}
            >
              Share
            </button>
          </div>
        )}
        <div className="flex-between txn-head">
          <div className="left">
            <span
              className="circle"
              style={{
                "--bg-txn":
                  data.transaction_type === "credit" ? "#EDFFEA" : "#FFEAEA",
              }}
            >
              <img
                src={data.transaction_type === "credit" ? credit : debit}
                width={10}
              />{" "}
            </span>
            <label>
              {data.transaction_type === "credit"
                ? "Received Money"
                : "Sent Money"}
              <br />
              {data.transaction_type === "credit" ? "from " : "to "}

              <b>
                {data?.counterparty_account_name ??
                  data.beneficiary_details?.beneficiary_name}{" "}
                (****
                {data?.counterparty_account_number?.slice?.(-4)})
              </b>
              <br />
              <span className={`status status_${data.status?.toLowerCase()}`}>
                {data.status}
              </span>
            </label>
          </div>
          <div className="right">
            <h2
              style={{
                textAlign: "right",
              }}
            >
              {currencyMapper[data?.currency]} {amountFormat(data?.amount)}
            </h2>
          </div>
        </div>
        <ul className="tab-menu">
          <li
            className={activeTab === "details" ? "active" : ""}
            onClick={() => setActiveTab("details")}
          >
            Details
          </li>
          {attach?.data?.attachments?.length ? (
            <li
              className={activeTab === "attach" ? "active" : ""}
              onClick={() => setActiveTab("attach")}
            >
              Attachments
            </li>
          ) : null}
          {/* <li
          className={activeTab === "history" ? "active" : ""}
          onClick={() => setActiveTab("history")}
        >
          History
        </li>
        <li
          className={activeTab === "comments" ? "active" : ""}
          onClick={() => setActiveTab("comments")}
        >
          Comments
        </li> */}
        </ul>
        {activeTab === "attach" && (
          <div className="imgs-wrap">
            {attach?.data?.attachments?.map((ele) => {
              return (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <div className="img">
                    <img
                      src={
                        [
                          "csv",
                          "xls",
                          "xlsx",
                          "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        ].includes(getFileExtension(ele.filename))
                          ? "https://cdn-icons-png.flaticon.com/256/732/732220.png"
                          : ["doc", "docx"].includes(
                              getFileExtension(ele.filename)
                            )
                          ? "https://cdn-icons-png.flaticon.com/512/732/732226.png"
                          : getFileExtension(ele.filename) === "pdf"
                          ? pdfIcon
                          : ele.url
                      }
                    />
                    {["pdf", "jpeg", "jpg", "png"]?.includes(
                      getFileExtension(ele.filename)
                    ) ? (
                      <img
                        src={eye}
                        alt=""
                        className="file-eye"
                        onClick={() => {
                          setIsPreviewOpen({
                            open: true,
                            url: ele.url,
                            fileType:
                              getFileExtension(ele.filename) === "pdf"
                                ? "pdf"
                                : "",
                          });
                        }}
                      />
                    ) : (
                      <img
                        src={download}
                        alt=""
                        className="file-download"
                        onClick={() => {
                          window.open(ele.url, "_blank");
                        }}
                        width={15}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {activeTab === "details" && (
          <>
            <div className="company-details txn-details">
              <h3>Transaction Details</h3>
              {/* Transaction Details */}
              <div className="item">
                <label>Transaction Reference Number</label>
                <span>
                  {data?.payment_id
                    ? data?.payment_id
                    : data?.transaction_ref_no}
                </span>
              </div>
              <div className="item">
                <label>Reference Number</label>
                <span>{data?.reference_number}</span>
              </div>
              <div className="item">
                <label>Transaction Type</label>
                <span
                  className="i-flex-center"
                  style={{
                    color: "#333",
                    textTransform: "capitalize",
                  }}
                >
                  <img
                    src={data.transaction_type === "credit" ? credit : debit}
                    width={10}
                  />{" "}
                  {data.transaction_type}
                </span>
              </div>
              <div className="item">
                <label>Status</label>
                <span
                  className={`status status_${data.status?.toLowerCase()}`}
                  style={{
                    paddingRight: 0,
                  }}
                >
                  {data.status}
                </span>
              </div>
              <div className="item">
                <label>Created Date & Time</label>
                <span>
                  {moment(
                    data?.created_d_time
                      ? data?.created_d_time
                      : data.created_at
                  ).format("MMM DD, YYYY, hh:mm A")}
                </span>
              </div>
              <div className="item">
                <label>Posted Date & Time</label>
                <span>
                  {data?.transaction_d_time
                    ? moment(data.transaction_d_time).format(
                        "MMM DD, YYYY, hh:mm A"
                      )
                    : "-"}
                </span>
              </div>
              <div className="item">
                <label>Transfer Mode</label>
                <span>
                  {
                    p_types?.find(
                      (ele) =>
                        ele.value === data.transfer_mode ||
                        (data.transfer_mode === "WITHINBANK" &&
                          ele.value === "WITHIN_BANK")
                    )?.label
                  }
                </span>
              </div>
              <div className="item">
                <label>Description</label>
                <span>{data.detail_name ?? data.description}</span>
              </div>
              <div className="item">
                <label>Notes</label>
                <span>
                  {data?.beneficiary_payment_info?.description ?? "-"}
                </span>
              </div>

              <h3>
                {data?.transaction_type === "credit" ? "Sender" : "Recipient"}{" "}
                Details
              </h3>
              <div className="item">
                <label>Account Name</label>
                <span>
                  {data.counterparty_account_name ??
                    data.beneficiary_details?.beneficiary_name}
                </span>
              </div>
              <div className="item">
                <label>Account Number</label>
                <span>
                  <AccountDisplay
                    accountNumber={data.counterparty_account_number}
                    showEye={true}
                  />
                </span>
              </div>
              <div className="item">
                <label>Routing / SWIFT Code</label>
                <span>
                  {data?.counterparty_account_institution_id ??
                    data?.recipient_routing_code ??
                    data?.recipient_swift_code}
                </span>
              </div>
              <div className="item">
                <label>Type</label>
                <span style={{ textTransform: "capitalize" }}>
                  {data?.counterparty_account_person_type_code === "L" ||
                  data?.beneficiary_details?.recipient_type === "legal"
                    ? "Business"
                    : data?.counterparty_account_person_type_code === "P"
                    ? "Person"
                    : data?.beneficiary_details?.recipient_type}
                </span>
              </div>
              <div className="item">
                <label>Address</label>
                {getAddress()}
              </div>

              {/* Sender Details */}

              <h3>
                {data?.transaction_type === "credit" ? "Recipient" : "Sender"}{" "}
                Details
              </h3>
              <div className="item">
                <label>Account Name</label>
                <span>{data.sender_account_details?.account_name}</span>
              </div>
              <div className="item">
                <label>Account Number</label>
                <span>
                  <AccountDisplay
                    accountNumber={data.overparty_account_number}
                    showEye={true}
                  />
                </span>
              </div>
              <div className="item">
                <label>Routing / SWIFT Code</label>
                <span>{data?.sender_account_details?.routing_code}</span>
              </div>
              {/* <div className="item">
                <label>BIC Code</label>
                <span>{data?.sender_account_details?.bic_code}</span>
              </div> */}
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default TxnDetailModal;
