import React, { useCallback, useEffect, useState } from "react";
import SearchInput from "../SearchInput";
import more from "../../../assets/icons/more.png";
import { Table, Dropdown, Menu, ConfigProvider, Select } from "antd";
import "./table.css";
import { PlusCircleOutlined, FilterOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
export const sort_key = {
  from_date: "Start date",
  to_date: "End date",
};

const TableWrap = ({
  columns,
  data,
  pagination = {},
  scroll,
  rowSelection,
  className,
  addNewBtn,
  btnsComponent,
  btnsOnly,
  handleSearch,
  selectedRows,
  approveClick,
  approveLoading,
  renderEmpty,
  rejectClick,
  expandable,
  hasExport,
  onExport,
  ...props
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeButton, setActiveButton] = useState("");
  const [activeFilter, setActiveFilter] = useState("");
  const handleCreatePaginator = useCallback((total, range) => {
    return `Showing ${range[0]} to ${range[1]} of ${total} entires`;
  }, []);

  useEffect(() => {
    if (props.activeIndex) {
      setActiveButton("Active");
      setActiveFilter("A");
    }
    // eslint-disable-next-line
  }, [props.activeIndex]);

  useEffect(() => {
    if (props.activeButton) {
      let obj = props.topFilters?.find(
        (ele) => ele.filter === props.activeButton
      );
      if (obj?.label) {
        setActiveButton(obj.label);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeButton]);

  React.useEffect(() => {
    setDropMenu(
      <Menu>
        {props?.topFilters?.map((ele, i) =>
          (activeIndex === 0 ? i !== 0 : i !== activeIndex) ? (
            <Menu.Item key={i}>
              <button
                className="tableFilter"
                onClick={() => {
                  props.handleFilter && props.handleFilter(ele.filter);
                  setActiveIndex(i);
                  setActiveButton(ele.label);
                  setActiveFilter(ele.filter);
                }}
              >
                <span>{ele.label}</span>
              </button>
            </Menu.Item>
          ) : null
        )}
      </Menu>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);
  const handleMenuClick = () => {};

  const [dropMenu, setDropMenu] = useState(
    <Menu>
      {props?.topFilters?.map((ele, i) =>
        i !== 0 ? (
          <Menu.Item key={i}>
            <button
              className="tableFilter"
              onClick={() => {
                props.handleFilter && props.handleFilter(ele.filter);
                setActiveIndex(i);
                setActiveButton(ele.label);
                setActiveFilter(ele.filter);
                handleMenuClick();
              }}
            >
              <span>{ele.label}</span>
            </button>
          </Menu.Item>
        ) : null
      )}
    </Menu>
  );

  const is_admin = useSelector(
    (state) => state?.auth?.localVariables?.is_admin
  );

  useEffect(() => {
    setDropMenu(
      <Menu>
        {props?.topFilters?.map((ele, i) =>
          !ele.isActive ? (
            <Menu.Item key={i}>
              <button
                className="tableFilter"
                onClick={() => {
                  props.handleFilter && props.handleFilter(ele.filter);
                  setActiveIndex(i);
                  setActiveButton(ele.label);
                  setActiveFilter(ele.filter);
                  handleMenuClick();
                }}
              >
                <span>{ele.label}</span>
              </button>
            </Menu.Item>
          ) : null
        )}
      </Menu>
    );
    // eslint-disable-next-line
  }, [is_admin, props.activeButton]);

  return (
    <div className="paycraft-table">
      {btnsOnly && (
        <div className="top-filter">
          <div className="flt-btns"></div>
          <div className="d-flex">{btnsComponent}</div>
        </div>
      )}
      {props.isTopFilter && (
        <div className="top-filter">
          <div className="flt-btns">
            {props?.topFilters?.map((ele, i) =>
              ele.isActive || (ele.isActive === undefined && i === 0) ? (
                <button
                  key={i}
                  className="active"
                  onClick={() => {
                    props.handleFilter &&
                      props.handleFilter(activeFilter || ele.filter);
                    setActiveIndex(i);
                  }}
                >
                  <span>{activeButton || ele.label}</span>
                </button>
              ) : null
            )}
            {!props.filter && (
              <Dropdown overlay={dropMenu}>
                <button
                  onClick={() => {
                    // setActiveIndex(100);
                  }}
                >
                  <FilterOutlined />
                </button>
              </Dropdown>
            )}
            {btnsComponent}
          </div>
          <div className="d-flex">
            {selectedRows?.length > 0 && (
              <div className="action-btns">
                <button
                  className="approve"
                  disabled={approveLoading}
                  onClick={approveClick}
                >
                  Approve
                </button>
                <button
                  className="reject"
                  disabled={approveLoading}
                  onClick={rejectClick}
                >
                  Reject
                </button>
              </div>
            )}
            {addNewBtn && addNewBtn()}
            {!props.isSearch && (
              <SearchInput
                placeholder={props.placeholder}
                handleSearch={(val) => {
                  handleSearch && handleSearch(val);
                }}
              />
            )}
            {hasExport && (
              <div className="mr1">
                <Select
                  placeholder="Export as"
                  options={[
                    {
                      label: "PDF",
                      value: "PDF",
                    },
                    {
                      label: "CSV",
                      value: "CSV",
                    },
                  ]}
                  onChange={(value) => {
                    onExport(value);
                  }}
                />
              </div>
            )}
            {(props.is_admin === false || props.is_admin === "false") && (
              <div className="flt-btns">
                <button
                  className="active"
                  onClick={(event) =>
                    props.handleAddButton && props.handleAddButton(event)
                  }
                >
                  <span>
                    <PlusCircleOutlined />
                    {props.module === "Report"
                      ? " New Report"
                      : ` Record ${props.module}`}
                  </span>
                </button>
              </div>
            )}
            {!props.isMore && (
              <Dropdown
                overlay={
                  <Menu>
                    <label className="dim-text ml1">Sort By</label>
                    {props?.dotMenu?.map((ele, i) => (
                      <Menu.Item key={i}>
                        <button
                          className="tableFilter"
                          onClick={() => {
                            props.handleSort && props.handleSort(ele);
                          }}
                        >
                          <span style={{ textTransform: "capitalize" }}>
                            {sort_key[ele]
                              ? sort_key[ele]
                              : ele?.replace("_", " ")}
                          </span>
                        </button>
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <button className="more">
                  <img src={more} alt="more" width={20} />
                </button>
              </Dropdown>
            )}
          </div>
        </div>
      )}
      <ConfigProvider renderEmpty={renderEmpty}>
        <Table
          rowKey={props.rowKey ? props.rowKey : "id"}
          columns={columns}
          dataSource={data}
          onRow={(record) => {
            return {
              onClick: (event) =>
                props.handleRowClick && props.handleRowClick(event, record),
            };
          }}
          pagination={{
            ...pagination,
            showTotal: handleCreatePaginator,
            showSizeChanger: false,
          }}
          scroll={scroll}
          rowSelection={rowSelection}
          className={className}
          expandable={expandable}
        />
      </ConfigProvider>
    </div>
  );
};

export default TableWrap;
