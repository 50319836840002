import axios from "axios";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useFieldArray } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getCompanyCountries } from "../../actions/companySettings";
import { FormInput, FormSelect } from "../inputs";
import { countries } from "../Settings/Organisation/country";
import { useTranslation } from "react-i18next";

const BillingAddress = ({
  control,
  errors,
  register,
  billing_address,
  setValue,
  addressRequired,
}) => {
  const dispatch = useDispatch();
  const pinRef = useRef();

  const { fields } = useFieldArray({
    control,
    name: "billing_address",
  });
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getCompanyCountries());
  }, []);

  const handleSearchCountry = (value, country, index) => {
    if (pinRef.current) {
      clearTimeout(pinRef.current);
    }
    pinRef.current = setTimeout(() => {
      axios
        .get(
          "https://api.worldpostallocations.com/pincode?postalcode=" +
            value +
            "&countrycode=" +
            countries?.find((ele) => ele.label === country)?.code +
            ""
        )
        .then((response) => {
          setValue(
            `billing_address.${index}.state`,
            response?.data?.result?.[0]?.state
          );
        });
    }, 700);
  };

  return (
    <>
      {fields.map((personData, index) => (
        <div key={`billing-${index}`}>
          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`billing_address.${index}.address`, {
                required: addressRequired ? "Street one is required" : false,
              })}
              label={t("Street 1")}
              required={addressRequired}
              placeholder="Address"
              errorMessage={
                errors.billing_address?.[index]?.["address"]?.["message"]
              }
            />
          </div>
          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`billing_address.${index}.address_two`, {
                required: false,
              })}
              label={t("Street 2")}
              placeholder="Address"
              errorMessage={
                errors.billing_address?.[index]?.["address_two"]?.["message"]
              }
            />
          </div>
          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`billing_address.${index}.city`, {
                required: addressRequired ? "City is required" : false,
              })}
              required={addressRequired}
              label={t("City/County")}
              placeholder="City"
              errorMessage={
                errors.billing_address?.[index]?.["city"]?.["message"]
              }
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`billing_address.${index}.state`, {
                // required: "State/Region is Required",
              })}
              label={t("State/Region")}
              placeholder="State"
              errorMessage={
                errors.billing_address?.[index]?.["state"]?.["message"]
              }
              // required={true}
            />
          </div>
          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`billing_address.${index}.zipcode`, {
                required: addressRequired ? "Zip is required" : false,
                // onChange: (e) => {
                //   handleSearchCountry(
                //     e.target.value,
                //     billing_address?.[index]?.country,
                //     index
                //   );
                // },
              })}
              required={addressRequired}
              placeholder="Zip"
              label="Zip"
              errorMessage={
                errors.billing_address?.[index]?.["zipcode"]?.["message"]
              }
            />
          </div>
          <div className={`pp-form-item`}>
            <FormSelect
              control={control}
              {...register(`billing_address.${index}.country`, {
                required: addressRequired ? "Country is required" : false,
              })}
              inline
              label={t("country_region")}
              placeholder={t("select")}
              options={countries?.map((ele) => ({
                label: (
                  <>
                    <img
                      src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${ele.code?.toLowerCase()}.svg`}
                      className="mr1"
                      style={{
                        width: "2rem",
                        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
                      }}
                    />{" "}
                    {ele.label}
                  </>
                ),
                value: ele.code,
                code: ele.value,
                country: ele.label,
              }))}
              hideCreateOption={true}
              required={addressRequired}
              errorMessage={
                errors.billing_address?.[index]?.["country"]?.["message"]
              }
              filterKey={"country"}
            />
          </div>
          {addressRequired ? null : (
            <div className="pp-form-item">
              <FormInput
                number={true}
                control={control}
                {...register(`billing_address.${index}.phone`, {
                  required: addressRequired ? "Phone is required" : false,
                })}
                required={addressRequired}
                label={t("phone")}
                errorMessage={
                  errors.billing_address?.[index]?.["phone"]?.["message"]
                }
              />
            </div>
          )}

          {/* <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`billing_address.${index}.fax`)}
              label="Fax"
              errorMessage={errors.billing_address?.[index]?.['fax']?.['message']}
            />
          </div> */}
        </div>
      ))}
    </>
  );
};

export default BillingAddress;
