import React from "react";
import { useForm } from "react-hook-form";
import card_chip from "../../assets/icons/card_chip.png";
import card_name from "../../assets/images/visa.png";
import card_wifi from "../../assets/icons/card_wifi.png";
import close from "../../assets/icons/close.png";
import { FormInput, FormSelect } from "../inputs";
import FormRadio from "../inputs/FormRadio";

const AddCard = ({ setOpenAddNew }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      cardType: "",
      cardholder: "",
      nickname: "",
      lockToMerchant: false,
      limitType: "",
      spendingLimit: "",
    },
  });

  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <div className="upload_payment_parent send-payment create-card">
      <div className="flex-between sec-head">
        <div className="tabsSection">
          <span className="active">Create Card</span>
        </div>
        <img
          src={close}
          width={20}
          onClick={() => {
            setOpenAddNew(false);
          }}
        />
      </div>
      <div className="hr" />
      <div className="card-form">
        <div className="w50">
          <form onSubmit={handleSubmit(onSubmit)}>
            <p className="form-header">Card Type</p>
            <div className="pp-form-item">
              <FormRadio
                inline
                label={"Virtual or Physical"}
                control={control}
                options={[
                  { label: "Physical", value: "Physical" },
                  { label: "Virtual", value: "Virtual" },
                ]}
                required={true}
                {...register("cardType", {
                  required: "Please select the card type.",
                })}
                errors={errors}
                hideCreateOption={true}
              />
            </div>
            <p className="form-header mt2">Basics</p>
            <div className="pp-form-item">
              <FormInput
                control={control}
                required
                {...register("card_holder_name", {
                  required: "Card holder name is required.",
                })}
                errors={errors}
                label={"Card Holder Name"}
              />
            </div>
            <div className="pp-form-item">
              <FormSelect
                inline
                label={"Account"}
                control={control}
                required={true}
                options={[]}
                {...register("account", {
                  required: "Please select the account.",
                })}
                errors={errors}
                hideCreateOption={true}
                placeholder={"Select Account"}
              />
            </div>
            <div className="pp-form-item">
              <FormInput
                control={control}
                required
                {...register("nickname", {
                  required: "Nickname is required.",
                })}
                errors={errors}
                label={"Nickname"}
              />
            </div>
            <p className="form-header mt2">Limits</p>
            <div className="pp-form-item merchant-lock">
              <input
                type="checkbox"
                {...register("lockToMerchant")}
                className="hidden-checkbox"
                id="limit-check"
              />
              <span
                className="custom-checkbox-box"
                onClick={() => {
                  document.getElementById("limit-check").click();
                }}
              ></span>
              <p>
                <label>Lock to a merchant</label>
                <span>Tighter spending control with higher limits</span>
              </p>
            </div>

            <div className="pp-form-item">
              <FormSelect
                inline
                label={"Limit Type"}
                control={control}
                required={true}
                options={[]}
                {...register("limitType", {
                  required: "Please select the card holder.",
                })}
                errors={errors}
                hideCreateOption={true}
              />
            </div>
            <div className="pp-form-item">
              <FormInput
                control={control}
                prefix={"$"}
                required
                {...register("spendingLimit", {
                  required: "Spending limit is required.",
                })}
                errors={errors}
                label={"Spending Limit"}
              />
            </div>
            <button className="mt2 mb2 send-btn">Create Card </button>
          </form>
        </div>
        <div className="w50 card-show">
          <div className="debit-wrap">
            <DebitCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export const DebitCard = ({ cardData }) => {
  return (
    <div className="debit-card">
      <div className="z_card"></div>
      <h2>{cardData?.company_name ?? "Company Name"}</h2>
      <div className="flex-between mt1">
        <img src={card_chip} width={30} alt={"card_chip"} />
        <img src={card_wifi} width={15} alt={"card_wifi"} />
      </div>
      <div className="mt1">
        <span>
          {cardData?.data?.maskCardNumber?.substr(0, 4) ?? "XXXX"} XXXX XXXX{" "}
          {cardData?.data?.maskCardNumber?.substr(15) ?? "XXXX"}
        </span>
        <br />
        <span style={{ marginTop: 5, fontSize: "0.85rem" }}>
          {cardData?.card_reference_no ?? "--/--"}
        </span>
      </div>
      <div className="flex-between mt1">
        <span style={{ fontSize: "0.85rem" }}>Card Holder Name</span>
        <img src={card_name} alt={"card_name"} width={40} />
      </div>
    </div>
  );
};

export default AddCard;
