import { takeEvery, takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import {
  sagaDocuments,
  sagaFunctions,
  sagaFunctionsSettings
} from "../../utils";
import {
  AddAdvancesField,
  AddCompanySetting,
  AddCostCenterField,
  AddDesignations,
  AddMerchantsField,
  AddProjectsField,
  AddReportField,
  AddRoles,
  AddTransactionField,
  AddTripsField,
  AddUserFields,
  AdvanceApproval,
  BillApproval,
  CompanyCurrency,
  CompanyProfile,
  CompanyProfileImages,
  CompanySetting,
  CompanySettingStatus,
  CreateBranch,
  CreateCompanyMultipleCurrency,
  CreateExchangeRate,
  CreateReminderAPI,
  DeleteAdvancesField,
  DeleteCompanySetting,
  DeleteCostCenterField,
  DeleteDesignations,
  DeleteMerchantsField,
  DeleteProjectsField,
  DeleteReportField,
  DeleteRole,
  DeleteTransactionField,
  DeleteTripsField,
  DeleteUserFields,
  EnableDisableCostCenterField,
  EnableDisableMarchentField,
  EnableDisableProjectField,
  GetAdvancesField,
  GetAllBranch,
  GetAllBranchHeader,
  GetAllBranchList,
  GetAllCompanyDetails,
  GetAllCountries,
  GetAllCurrency,
  GetAllExchangeRates,
  GetBranchID,
  GetCardVenders,
  GetCompanyBaseCurrency,
  GetCompanyCities,
  GetCompanyCountries,
  GetCompanyMultipleCurrency,
  GetCompanyStates,
  GetCompanyTimeZones,
  GetCostCenterField,
  GetCustomFields,
  GetDesignations,
  GetMerchantsField,
  GetPortalPermissions,
  GetProjectsField,
  GetReminderAPI,
  GetReportField,
  GetRoles,
  GetRolesPermissions,
  GetSingleDesignations,
  GetSingleRoles,
  GetSingleTransactionField,
  GetSingleUserFields,
  GetTransactionField,
  GetTripsField,
  GetUserFields,
  GetUserFieldsColumnTypes,
  ReportsApproval,
  SwitchBrach,
  ToggleExchangeRate,
  TripApproval,
  UpdateAdvanceApproval,
  UpdateAdvancesField,
  UpdateBillApproval,
  UpdateCompanyAdvances,
  UpdateCompanyProfile,
  UpdateCompanyProfileImages,
  UpdateCompanyReport,
  UpdateCompanyTransaction,
  UpdateCompanyTrips,
  UpdateCostCenterField,
  UpdateDesignations,
  UpdateMerchantsField,
  UpdateProjectsField,
  UpdateReportField,
  UpdateReportsApproval,
  UpdateRoles,
  UpdateTransactionField,
  UpdateTripApproval,
  UpdateTripsField,
  UpdateUserFields,
} from "./companySettings";

function getCompanyProfile(req) {
  return sagaFunctionsSettings(
    CompanyProfile,
    "get",
    apis.companyProfile,
    req.payload
  )();
}

function companyProfileImages(req) {
  return sagaDocuments(
    CompanyProfileImages,
    "get",
    apis.companyProfileImages,
    req.payload
  )();
}

function getCompanyCurrency(req) {
  return sagaFunctions(
    CompanyCurrency,
    "get",
    apis.company_currencies,
    req.payload
  )();
}

function updateCompanyProfile(req) {
  return sagaFunctionsSettings(
    UpdateCompanyProfile,
    "post",
    apis.updateCompanyProfile,
    req.payload
  )();
}

function updateCompanyProfileImages(req) {
  return sagaDocuments(
    UpdateCompanyProfileImages,
    "post",
    apis.updateCompanyProfileImages,
    req.payload,
    {
      contentType: "multipart/formdata",
    }
  )();
}

function getReportsApproval(req) {
  return sagaFunctionsSettings(
    ReportsApproval,
    "get",
    apis.reportApprovals,
    req.payload
  )();
}

function updateReportsApproval(req) {
  return sagaFunctionsSettings(
    UpdateReportsApproval,
    "post",
    apis.updateReportApprovals,
    req.payload
  )();
}
function getTzs(req) {
  return sagaFunctionsSettings(
    GetCompanyTimeZones,
    "get",
    apis.getAllTimeZones,
    req.payload
  )();
}
function getAdvanceApproval(req) {
  return sagaFunctionsSettings(
    AdvanceApproval,
    "get",
    apis.advanceApprovals,
    req.payload
  )();
}

function updateAdvanceApproval(req) {
  return sagaFunctionsSettings(
    UpdateAdvanceApproval,
    "post",
    apis.updateAdvanceApprovals,
    req.payload
  )();
}
function getBillApproval(req) {
  let required;
  let invoice = req?.payload?.invoice;
  let type = req?.payload?.type;
  if (type === "SALES_ORDER_APPROVAL") {
    required = apis.salesOrderApproval;
  } else if (type === "ESTIMATE_APPROVAL") {
    required = apis.estimationApproval;
  } else if (type === "PURCHASE_ORDER_APPROVAL") {
    required = apis.purchaseApproval;
  } else if (type === "PAYMENT_APPROVAL") {
    required = apis.paymentApproval;
  } else if (type === "BULK_PAYMENT_APPROVAL") {
    required = apis.bulkPayApproval;
  } else {
    required = invoice ? apis.invoiceApprovals : apis.billApprovals;
  }
  delete req.payload.invoice;
  delete req?.payload?.type;
  return sagaFunctionsSettings(BillApproval, "get", required, req.payload)();
}

function getTripApproval(req) {
  return sagaFunctionsSettings(
    TripApproval,
    "get",
    apis.tripApprovals,
    req.payload
  )();
}

function updateBillApproval(req) {
  let invoice = req.others.invoice;
  let required;
  let type = req?.others?.type;
  if (type === "SALES_ORDER_APPROVAL") {
    required = apis.salesOrderApprovalSave;
  } else if (type === "ESTIMATE_APPROVAL") {
    required = apis.estimationApprovalSave;
  } else if (type === "PURCHASE_ORDER_APPROVAL") {
    required = apis.purchaseApprovalSave;
  } else if (type === "PAYMENT_APPROVAL") {
    required = apis.paymentApprovalSave;
  } else if (type === "BULK_PAYMENT_APPROVAL") {
    required = apis.bulkPayApprovalSave;
  } else {
    required = invoice ? apis.updateInvoiceApprovals : apis.updatebillApprovals;
  }
  delete req.others?.invoice;
  delete req?.others?.type;
  return sagaFunctionsSettings(
    UpdateBillApproval,
    "post",
    required,
    req.payload
  )();
}

function updateTripApproval(req) {
  return sagaFunctionsSettings(
    UpdateTripApproval,
    "post",
    apis.updateTripApprovals,
    req.payload
  )();
}

function getCompanySetting(req) {
  const typeArray = [
    "estimates",
    "sales_order",
    "invoices",
    "purchase_orders",
    "bills",
  ];
  let type = req?.payload?.type;
  let required;
  let contentType;
  if (typeArray.includes(type)) {
    required = apis.companySetting;
    contentType = "application/json";
  } else {
    required = apis.companySetting;
    // contentType = "multipart/formdata"
  }
  return sagaFunctionsSettings(CompanySetting, "get", required, req.payload, {
    "Content-Type": contentType,
    Authorization: localStorage.getItem("token"),
  })();
}

function updateCompanySettingStatus(req) {
  return sagaFunctions(
    CompanySettingStatus,
    "post",
    apis.companySettingStatus,
    req.payload
  )();
}

function addCompanySetting(req) {
  return sagaFunctions(
    AddCompanySetting,
    "post",
    apis.updateTransaction,
    req.payload
  )();
}

function deleteCompanySetting(req) {
  return sagaFunctions(
    DeleteCompanySetting,
    "post",
    apis.deleteCompanySetting,
    req.payload
  )();
}

//  ===============================================================================================
// ===============================================================================================
// ===============================================================================================

function updateCompanyTransaction(req) {
  return sagaFunctionsSettings(
    UpdateCompanyTransaction,
    "post",
    apis.updateTransaction,
    req.payload
  )();
}

function getTransactionField(req) {
  return sagaFunctionsSettings(
    GetTransactionField,
    "get",
    apis.transactionField,
    req.payload
  )();
}

function getSingleTransactionField(req) {
  return sagaFunctionsSettings(
    GetSingleTransactionField,
    "get",
    apis.singleTransactionField,
    req.payload
  )();
}

function addTransactionField(req) {
  return sagaFunctionsSettings(
    AddTransactionField,
    "post",
    apis.createTransaction,
    req.payload
  )();
}

function deleteTransactionField(req) {
  return sagaFunctionsSettings(
    DeleteTransactionField,
    "post",
    apis.deleteTransactionField,
    req.payload
  )();
}

function updateTransactionField(req) {
  return sagaFunctionsSettings(
    DeleteTransactionField,
    "post",
    apis.updateTransactionField,
    req.payload
  )();
}

//  ===============================================================================================
// ===============================================================================================
// ===============================================================================================

function updateCompanyReport(req) {
  return sagaFunctionsSettings(
    UpdateCompanyReport,
    "post",
    apis.updateReport,
    req.payload
  )();
}

function getReportField(req) {
  return sagaFunctionsSettings(
    GetReportField,
    "get",
    apis.reportField,
    req.payload
  )();
}

function addReportField(req) {
  return sagaFunctionsSettings(
    AddReportField,
    "post",
    apis.createReportField,
    req.payload
  )();
}

function deleteReportField(req) {
  return sagaFunctionsSettings(
    DeleteReportField,
    "post",
    apis.deleteReportField,
    req.payload
  )();
}

function updateReportField(req) {
  return sagaFunctionsSettings(
    DeleteReportField,
    "post",
    apis.updateReportField,
    req.payload
  )();
}

//  ===============================================================================================
// ===============================================================================================
// ===============================================================================================

function updateCompanyAdvances(req) {
  return sagaFunctions(
    UpdateCompanyAdvances,
    "post",
    apis.updateCompAdvances,
    req.payload
  )();
}

function getAdvancesField(req) {
  return sagaFunctionsSettings(
    GetAdvancesField,
    "get",
    apis.advancesField,
    req.payload
  )();
}

function addAdvancesField(req) {
  return sagaFunctionsSettings(
    AddAdvancesField,
    "post",
    apis.createAdvances,
    req.payload
  )();
}

function deleteAdvancesField(req) {
  return sagaFunctionsSettings(
    DeleteAdvancesField,
    "post",
    apis.deleteAdvancesField,
    req.payload
  )();
}

function updateAdvancesField(req) {
  return sagaFunctionsSettings(
    DeleteAdvancesField,
    "post",
    apis.updateAdvancesField,
    req.payload
  )();
}

//  ===============================================================================================
// ===============================================================================================
// ===============================================================================================

function getTripsField(req) {
  const typeArray = [
    "estimates",
    "sales_order",
    "invoices",
    "purchase_orders",
    "bills",
  ];
  let type = req?.payload?.type;
  console.log("302=", req?.payload);
  let required;
  if (typeArray.includes(type)) {
    required = apis.getFieldsAPI;
  } else {
    required = apis.tripsField;
  }
  // delete req?.payload?.type;
  return sagaFunctionsSettings(GetTripsField, "get", required, req.payload)();
}

function addTripsField(req) {
  const typeArray = [
    "estimates",
    "sales_order",
    "invoices",
    "purchase_orders",
    "bills",
  ];
  let type = req?.payload?.type;
  let required;
  if (typeArray.includes(type)) {
    required = apis.createFieldsAPI;
  } else {
    required = apis.createTripsField;
  }
  delete req?.payload?.type;
  return sagaFunctionsSettings(AddTripsField, "post", required, req.payload, {
    // "Content-Type": type ? "application/json" : "multipart/formdata",
    Authorization: localStorage.getItem("token"),
  })();
}

function updateCompanyTrips(req) {
  const typeArray = [
    "estimates",
    "sales_order",
    "invoices",
    "purchase_orders",
    "bills",
    "delivery_challan",
    "material_receipts",
    "qc_check",
  ];
  let type = req?.payload?.type;
  let required;
  let contentType;
  if (typeArray.includes(type)) {
    required = apis.updateReminderAPI;
    contentType = "application/json";
  } else {
    required = apis.updateTrips;
    // contentType = "multipart/formdata"
  }
  delete req?.payload?.type;
  return sagaFunctionsSettings(
    UpdateCompanyTrips,
    "post",
    required,
    req.payload,
    {
      "Content-Type": contentType,
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function deleteTripsField(req) {
  const typeArray = [
    "estimates",
    "sales_order",
    "invoices",
    "purchase_orders",
    "bills",
  ];
  let type = req?.payload?.type;
  let required;
  let contentType;
  if (typeArray.includes(type)) {
    required = apis.deleteFieldsAPI;
    contentType = "application/json";
  } else {
    required = apis.deleteTripsField;
    // contentType = "multipart/formdata"
  }
  delete req?.payload?.type;
  return sagaFunctionsSettings(
    DeleteTripsField,
    "post",
    required,
    req.payload,
    {
      "Content-Type": contentType,
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function updateTripsField(req) {
  const typeArray = [
    "estimates",
    "sales_order",
    "invoices",
    "purchase_orders",
    "bills",
  ];
  let type = req?.payload?.type;
  let required;
  let contentType;
  if (typeArray.includes(type)) {
    required = apis.updateFieldsAPI;
    contentType = "application/json";
  } else {
    required = apis.updateTripsField;
    // contentType = "multipart/formdata"
  }
  delete req?.payload?.type;
  return sagaFunctionsSettings(
    DeleteTripsField,
    "post",
    required,
    req.payload,
    {
      "Content-Type": contentType,
    }
  )();
}

function getCostCenterField(req) {
  return sagaFunctionsSettings(
    GetCostCenterField,
    "get",
    apis.costCenterField,
    req.payload
  )();
}

function addCostCenterField(req) {
  return sagaFunctionsSettings(
    AddCostCenterField,
    "post",
    apis.createNewCostCenter,
    req.payload
  )();
}

function deleteCostCenterField(req) {
  return sagaFunctionsSettings(
    DeleteCostCenterField,
    "post",
    apis.deleteCostCenterField,
    req.payload
  )();
}

function updateCostCenterField(req) {
  return sagaFunctionsSettings(
    UpdateCostCenterField,
    "post",
    apis.updateCostCenterField,
    req.payload
  )();
}
function enableDisableCostCenter(req) {
  return sagaFunctionsSettings(
    EnableDisableCostCenterField,
    "post",
    apis.enableDisableCostCenter,
    req.payload
  )();
}

//  ===============================================================================================
// ===============================================================================================
// ===============================================================================================

function getMerchantsField(req) {
  let type = req?.payload?.type;
  let requested;
  let apiBase = true;
  let method = "get";
  if (type === "items") {
    requested = apis.getPaymentItems;
  } else if (type === "taxes") {
    requested = apis.companyTaxTypes;
  } else if (type === "tds") {
    requested = apis.getTDSTypes;
  } else if (type === "unit") {
    requested =
      apis.unitTypeGETAPI + `?page_number=${req?.payload?.page_number}`;
    method = "post";
    delete req?.payload?.page_number;
  } else if (type === "warehouse") {
    requested = apis.warehouseGETAPI;
    delete req?.payload?.status;
  } else {
    requested = apis.getMerchantsField;
    apiBase = "";
  }
  delete req?.payload?.type;
  return sagaFunctionsSettings(
    GetMerchantsField,
    method,
    requested,
    req.payload,
    {
      Authorization: localStorage.getItem("token"),
    },
    apiBase
  )();
}

function addMerchantsField(req) {
  return sagaFunctionsSettings(
    AddMerchantsField,
    "post",
    apis.merchantsField,
    req.payload
  )();
}

function deleteMerchantsField(req) {
  return sagaFunctionsSettings(
    DeleteMerchantsField,
    "post",
    apis.deleteMerchantsField,
    req.payload
  )();
}

function updateMerchantsField(req) {
  return sagaFunctionsSettings(
    UpdateMerchantsField,
    "post",
    apis.updateMerchantsField,
    req.payload
  )();
}

function enabledisableMarchent(req) {
  let type = req?.payload?.type;
  delete req?.payload?.type;
  let requestAPI;
  if (type === "unit") {
    requestAPI = apis?.unitTypeEnableAPI;
  } else {
    requestAPI = apis?.enabledisableMarchent;
  }
  return sagaFunctionsSettings(
    EnableDisableMarchentField,
    "post",
    requestAPI,
    req.payload
  )();
}

//  ===============================================================================================
// ===============================================================================================
// ===============================================================================================

function getProjectsField(req) {
  return sagaFunctionsSettings(
    GetProjectsField,
    "get",
    apis.projectsField,
    req.payload
  )();
}

function addProjectsField(req) {
  return sagaFunctionsSettings(
    AddProjectsField,
    "post",
    apis.addNewProjectField,
    req.payload
  )();
}

function deleteProjectsField(req) {
  return sagaFunctionsSettings(
    DeleteProjectsField,
    "post",
    apis.deleteProjectsField,
    req.payload
  )();
}

function updateProjectsField(req) {
  return sagaFunctionsSettings(
    UpdateProjectsField,
    "post",
    apis.updateProjectsField,
    req.payload
  )();
}
function enableDisableProjectField(req) {
  return sagaFunctionsSettings(
    DeleteProjectsField,
    "post",
    apis.updateStatusProjectField,
    req.payload
  )();
}

//  ===============================================================================================
// ===============================================================================================
// ===============================================================================================

function getRolesPermissions(req) {
  return sagaFunctionsSettings(
    GetRolesPermissions,
    "get",
    apis.rolesPermissions,
    req.payload
  )();
}

function getRoles(req) {
  return sagaFunctionsSettings(GetRoles, "get", apis.getRoles, req.payload)();
}

function getSingleRoles(req) {
  return sagaFunctionsSettings(
    GetSingleRoles,
    "get",
    apis.getSingleRoles,
    req.payload
  )();
}

function addRoles(req) {
  return sagaFunctionsSettings(AddRoles, "post", apis.roles, req.payload)();
}

function updateRoles(req) {
  return sagaFunctionsSettings(
    UpdateRoles,
    "post",
    apis.updateRoles,
    req.payload
  )();
}

//  ===============================================================================================
// ===============================================================================================
// ===============================================================================================

function getUserFields(req) {
  return sagaFunctionsSettings(
    GetUserFields,
    "get",
    apis.getUserFields,
    req.payload
  )();
}

function getUserFieldsColumnTypes(req) {
  return sagaFunctions(
    GetUserFieldsColumnTypes,
    "get",
    apis.userFieldsColumns,
    req.payload
  )();
}

function getSingleUserFields(req) {
  return sagaFunctionsSettings(
    GetSingleUserFields,
    "get",
    apis.getSingleUserFields,
    req.payload
  )();
}

function addUserFields(req) {
  return sagaFunctionsSettings(
    AddUserFields,
    "post",
    apis.addUserFields,
    req.payload
  )();
}

function updateUserFields(req) {
  return sagaFunctionsSettings(
    UpdateUserFields,
    "post",
    apis.updateUserFields,
    req.payload
  )();
}

function deleteUserFields(req) {
  return sagaFunctionsSettings(
    DeleteUserFields,
    "post",
    apis.deleteUserFields,
    req.payload
  )();
}

//  ===============================================================================================
// ===============================================================================================
// ===============================================================================================

function getDesignations(req) {
  return sagaFunctionsSettings(
    GetDesignations,
    "get",
    apis.designations,
    req.payload
  )();
}

function getSingleDesignations(req) {
  return sagaFunctionsSettings(
    GetSingleDesignations,
    "get",
    apis.getSingleDesignations,
    req.payload
  )();
}

function addDesignations(req) {
  return sagaFunctionsSettings(
    AddDesignations,
    "post",
    apis.createDesignations,
    req.payload
  )();
}

function deleteDesignations(req) {
  return sagaFunctionsSettings(
    DeleteDesignations,
    "post",
    apis.deleteDesignations,
    req.payload
  )();
}

function updateDesignations(req) {
  return sagaFunctionsSettings(
    AddDesignations,
    "post",
    apis.updateDesignations,
    req.payload
  )();
}

function deleteRole(req) {
  return sagaFunctionsSettings(
    DeleteRole,
    "post",
    apis.deleteRole,
    req.payload
  )();
}

function getCustomFields(req) {
  return sagaFunctionsSettings(
    GetCustomFields,
    "get",
    apis.getCustomFields,
    req.payload
  )();
}

function getCompanyCities(req) {
  return sagaFunctionsSettings(
    GetCompanyCities,
    "post",
    apis.companyCities,
    req.payload
  )();
}
function getCompanyStates(req) {
  return sagaFunctionsSettings(
    GetCompanyStates,
    "post",
    apis.companyStates,
    req.payload
  )();
}
function getCompanyCountries(req) {
  return sagaFunctionsSettings(
    GetCompanyCountries,
    "post",
    apis.companyCountries,
    req.payload
  )();
}

// bill and invoice reminder
function getReminderAPI(req) {
  return sagaFunctions(
    GetReminderAPI,
    "get",
    apis.getReminderAPI,
    req.payload
  )();
}
function createReminderAPI(req) {
  return sagaFunctions(
    CreateReminderAPI,
    "post",
    apis.createReminderAPI,
    req.payload
  )();
}

// multi-currency start
function createCompanyMultipleCurrency(req) {
  return sagaFunctions(
    CreateCompanyMultipleCurrency,
    "post",
    req?.isUpdate
      ? apis.updateCompanyCurrencyAPIs
      : apis.createCompanyCurrencyAPIs,
    req?.payload?.company_currency?.[0],
    {},
    "settings"
  )();
}

function getCompanyMultipleCurrency(req) {
  return sagaFunctions(
    GetCompanyMultipleCurrency,
    "get",
    apis.getCompanyCurrencyAPIs,
    req.payload,
    {},
    "settings"
  )();
}
function getAllCurrency(req) {
  return sagaFunctions(
    GetAllCurrency,
    "get",
    apis.getAllCurrencyAPIs,
    req.payload,
    {},
    true
  )();
}
function getAllCountries(req) {
  return sagaFunctions(
    GetAllCountries,
    "get",
    apis.getAllCountriesAPIs,
    req.payload,
    {},
    "settings"
  )();
}

function createExchangeRate(req) {
  let formData = req?.payload?.exchange_Rate;
  return sagaFunctions(
    CreateExchangeRate,
    "post",
    req?.isUpdate ? apis.updateExchangeRateAPIs : apis.createExchangeRateAPIs,
    formData,
    {},
    "settings"
  )();
}

function getAllExchangeRates(req) {
  return sagaFunctions(
    GetAllExchangeRates,
    "get",
    apis.getAllExchangeRatesAPIs,
    req.payload,
    {},
    "settings"
  )();
}

function toggleExchangeRate(req) {
  return sagaFunctions(
    ToggleExchangeRate,
    "post",
    apis.toggleExchangeRateAPIs,
    req.payload,
    {},
    "settings"
  )();
}

function getAllCompanyDetails(req) {
  return sagaFunctions(
    GetAllCompanyDetails,
    "get",
    apis.companyDetailsAPIs,
    req.payload,
    {},
    "settings"
  )();
}

function createBranch(req) {
  let isUpdate = req?.payload?.id;
  return sagaFunctions(
    CreateBranch,
    "post",
    isUpdate ? apis.updateMultiBranch : apis.createMultiBranch,
    req.payload,
    {},
    "settings"
  )();
}

function getAllBranch(req) {
  return sagaFunctions(
    GetAllBranch,
    "get",
    apis.getAllBrach,
    req.payload,
    {},
    "settings"
  )();
}
function getAllBranchList(req) {
  return sagaFunctions(
    GetAllBranchList,
    "get",
    apis.getAllBrachList,
    req.payload,
    {},
    "settings"
  )();
}
function getAllBranchHeader(req) {
  const parent_id = localStorage?.getItem("parentID")
    ? localStorage?.getItem("parentID")
    : "";
  return sagaFunctions(
    GetAllBranchHeader,
    "get",
    apis.getAllBrach,
    req.payload,
    {},
    "settings"
  )();
}
function getBranchID(req) {
  return sagaFunctions(
    GetBranchID,
    "get",
    apis.getBrachID,
    req.payload,
    {},
    "settings"
  )();
}
function switchBrach(req) {
  return sagaFunctions(
    SwitchBrach,
    "get",
    apis.switchBrach,
    req.payload,
    {},
    "settings"
  )();
}

function getCardVenders(req) {
  return sagaFunctions(
    GetCardVenders,
    "get",
    apis.getCardVenders,
    req.payload,
    {},
    "settings"
  )();
}

function getCompanyBaseCurrency(req) {
  return sagaFunctions(
    GetCompanyBaseCurrency,
    "get",
    apis.getCompanyBaseCurrency,
    req.payload,
    {},
    "settings"
  )();
}

function getPortalPermissions(req) {
  const isCustomer = req.payload.isCustomer;
  delete req.payload.isCustomer;
  return sagaFunctions(
    GetPortalPermissions,
    "get",
    isCustomer ? apis.permissionAPIs : apis?.permissionGetAPIVendor,
    req.payload,
    {},
    "settings"
  )();
}
export function* companySettingWatcher() {
  yield takeLatest(CompanyProfile.REQUEST, getCompanyProfile);
  yield takeLatest(CompanyProfileImages.REQUEST, companyProfileImages);
  yield takeLatest(CompanyCurrency.REQUEST, getCompanyCurrency);
  yield takeLatest(DeleteRole.REQUEST, deleteRole);
  yield takeLatest(UpdateCompanyProfile.REQUEST, updateCompanyProfile);
  yield takeLatest(
    UpdateCompanyProfileImages.REQUEST,
    updateCompanyProfileImages
  );

  yield takeLatest(ReportsApproval.REQUEST, getReportsApproval);
  yield takeLatest(UpdateReportsApproval.REQUEST, updateReportsApproval);

  yield takeLatest(AdvanceApproval.REQUEST, getAdvanceApproval);
  yield takeLatest(UpdateAdvanceApproval.REQUEST, updateAdvanceApproval);

  yield takeLatest(TripApproval.REQUEST, getTripApproval);
  yield takeLatest(UpdateTripApproval.REQUEST, updateTripApproval);

  yield takeLatest(BillApproval.REQUEST, getBillApproval);
  yield takeLatest(UpdateBillApproval.REQUEST, updateBillApproval);

  yield takeLatest(CompanySetting.REQUEST, getCompanySetting);
  yield takeLatest(CompanySettingStatus.REQUEST, updateCompanySettingStatus);
  yield takeLatest(AddCompanySetting.REQUEST, addCompanySetting);
  yield takeLatest(DeleteCompanySetting.REQUEST, deleteCompanySetting);

  yield takeLatest(UpdateCompanyTransaction.REQUEST, updateCompanyTransaction);
  yield takeLatest(GetTransactionField.REQUEST, getTransactionField);
  yield takeLatest(
    GetSingleTransactionField.REQUEST,
    getSingleTransactionField
  );
  yield takeLatest(AddTransactionField.REQUEST, addTransactionField);
  yield takeLatest(DeleteTransactionField.REQUEST, deleteTransactionField);
  yield takeLatest(UpdateTransactionField.REQUEST, updateTransactionField);

  yield takeLatest(UpdateCompanyReport.REQUEST, updateCompanyReport);
  yield takeLatest(GetReportField.REQUEST, getReportField);
  yield takeLatest(AddReportField.REQUEST, addReportField);
  yield takeLatest(DeleteReportField.REQUEST, deleteReportField);
  yield takeLatest(UpdateReportField.REQUEST, updateReportField);

  yield takeLatest(UpdateCompanyAdvances.REQUEST, updateCompanyAdvances);
  yield takeLatest(GetAdvancesField.REQUEST, getAdvancesField);
  yield takeLatest(AddAdvancesField.REQUEST, addAdvancesField);
  yield takeLatest(DeleteAdvancesField.REQUEST, deleteAdvancesField);
  yield takeLatest(UpdateAdvancesField.REQUEST, updateAdvancesField);

  yield takeLatest(GetTripsField.REQUEST, getTripsField);
  yield takeLatest(AddTripsField.REQUEST, addTripsField);
  yield takeLatest(UpdateCompanyTrips.REQUEST, updateCompanyTrips);
  yield takeLatest(DeleteTripsField.REQUEST, deleteTripsField);
  yield takeLatest(UpdateTripsField.REQUEST, updateTripsField);

  yield takeLatest(GetCostCenterField.REQUEST, getCostCenterField);
  yield takeLatest(AddCostCenterField.REQUEST, addCostCenterField);
  yield takeLatest(DeleteCostCenterField.REQUEST, deleteCostCenterField);
  yield takeLatest(UpdateCostCenterField.REQUEST, updateCostCenterField);
  yield takeLatest(
    EnableDisableCostCenterField.REQUEST,
    enableDisableCostCenter
  );

  yield takeLatest(GetMerchantsField.REQUEST, getMerchantsField);
  yield takeLatest(AddMerchantsField.REQUEST, addMerchantsField);
  yield takeLatest(DeleteMerchantsField.REQUEST, deleteMerchantsField);
  yield takeLatest(UpdateMerchantsField.REQUEST, updateMerchantsField);
  yield takeLatest(EnableDisableMarchentField.REQUEST, enabledisableMarchent);

  yield takeLatest(GetProjectsField.REQUEST, getProjectsField);
  yield takeLatest(AddProjectsField.REQUEST, addProjectsField);
  yield takeLatest(DeleteProjectsField.REQUEST, deleteProjectsField);
  yield takeLatest(UpdateProjectsField.REQUEST, updateProjectsField);
  yield takeLatest(
    EnableDisableProjectField.REQUEST,
    enableDisableProjectField
  );

  yield takeLatest(GetRolesPermissions.REQUEST, getRolesPermissions);
  yield takeLatest(GetRoles.REQUEST, getRoles);
  yield takeEvery(GetSingleRoles.REQUEST, getSingleRoles);
  yield takeLatest(AddRoles.REQUEST, addRoles);
  yield takeLatest(UpdateRoles.REQUEST, updateRoles);

  yield takeLatest(GetUserFields.REQUEST, getUserFields);
  yield takeLatest(GetUserFieldsColumnTypes.REQUEST, getUserFieldsColumnTypes);
  yield takeLatest(GetSingleUserFields.REQUEST, getSingleUserFields);
  yield takeLatest(AddUserFields.REQUEST, addUserFields);
  yield takeLatest(UpdateUserFields.REQUEST, updateUserFields);
  yield takeLatest(DeleteUserFields.REQUEST, deleteUserFields);

  yield takeLatest(GetDesignations.REQUEST, getDesignations);
  yield takeLatest(AddDesignations.REQUEST, addDesignations);
  yield takeLatest(DeleteDesignations.REQUEST, deleteDesignations);
  yield takeLatest(UpdateDesignations.REQUEST, updateDesignations);
  yield takeLatest(GetSingleDesignations.REQUEST, getSingleDesignations);

  yield takeLatest(GetCustomFields.REQUEST, getCustomFields);

  yield takeLatest(GetCompanyCountries.REQUEST, getCompanyCountries);
  yield takeLatest(GetCompanyStates.REQUEST, getCompanyStates);
  yield takeLatest(GetCompanyCities.REQUEST, getCompanyCities);
  yield takeLatest(GetReminderAPI.REQUEST, getReminderAPI);
  yield takeLatest(CreateReminderAPI.REQUEST, createReminderAPI);

  // multi-currency start
  yield takeLatest(
    CreateCompanyMultipleCurrency.REQUEST,
    createCompanyMultipleCurrency
  );
  yield takeLatest(
    GetCompanyMultipleCurrency.REQUEST,
    getCompanyMultipleCurrency
  );
  yield takeLatest(GetAllCurrency.REQUEST, getAllCurrency);
  yield takeLatest(GetAllCountries.REQUEST, getAllCountries);
  yield takeLatest(CreateExchangeRate.REQUEST, createExchangeRate);
  yield takeLatest(GetAllExchangeRates.REQUEST, getAllExchangeRates);
  yield takeLatest(ToggleExchangeRate.REQUEST, toggleExchangeRate);
  yield takeLatest(GetAllCompanyDetails.REQUEST, getAllCompanyDetails);

  // multi branch
  yield takeLatest(CreateBranch.REQUEST, createBranch);
  yield takeLatest(GetAllBranch.REQUEST, getAllBranch);
  yield takeLatest(GetAllBranchList.REQUEST, getAllBranchList);
  yield takeLatest(GetAllBranchHeader.REQUEST, getAllBranchHeader);
  yield takeLatest(GetBranchID.REQUEST, getBranchID);
  yield takeLatest(SwitchBrach.REQUEST, switchBrach);
  yield takeLatest(GetCardVenders.REQUEST, getCardVenders);
  yield takeLatest(GetPortalPermissions.REQUEST, getPortalPermissions);
  yield takeLatest(GetCompanyBaseCurrency.REQUEST, getCompanyBaseCurrency);
  yield takeLatest(GetCompanyTimeZones.REQUEST, getTzs);
}
