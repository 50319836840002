import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Typography,
} from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  addRoles,
  GetSingleRoles,
  getSingleRoles,
  updateRoles,
} from "../../../../actions/companySettings";
import { useTranslation } from "react-i18next";
const _ = require("lodash");
const { Text } = Typography;

//  const { Panel } = Collapse;

//  import { validationRules } from '../../../utils';
//  const { Option } = Select;

const ValidationSchema = yup.object().shape({
  role_name: yup.string().required("Please provide role name"),
  description: yup.string(),
});

const AddRoles = (props) => {
  const [t] = useTranslation();
  const { id: currentId = "" } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [permissionsDataCheck, setPermissionsDataCheck] = React.useState({});
  const [selectAll, setSelectAll] = React.useState({
    read: false,
    write: false,
  });
  const singleRolesResponse = useSelector(
    (state) => state.companySetting.getSingleRoles || {}
  );

  const addRolesResponse = useSelector(
    (state) => state.companySetting.addRoles || {}
  );

  React.useEffect(() => {
    const { data: { status = "" } = {} } = addRolesResponse;
    if (status || status === false) {
      setSelectAll({
        read: false,
        write: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addRolesResponse]);

  React.useEffect(() => {
    if (currentId) {
      dispatch(getSingleRoles({ role_id: currentId }));
    } else {
      let obj = {};
      Object.keys(props.permissionsData).forEach((itm) => {
        Object.keys(props.permissionsData[itm]).forEach((ele) => {
          obj[ele] = {
            read: false,
            write: false,
          };
        });
      });
      setPermissionsDataCheck(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  React.useEffect(() => {
    return () => {
      dispatch({ type: GetSingleRoles.RESET });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    form.resetFields();
    if (singleRolesResponse.success && currentId) {
      const {
        data: {
          data: {
            role: { role_name = "", description = "" } = {},
            permissions = {},
          } = {},
        } = {},
      } = singleRolesResponse;

      form.setFieldsValue({
        role_name: role_name,
        description: description,
      });
      let obj = {};
      let initArr = [];
      let obj2 = {};

      Object.keys(props.permissionsData).forEach((itm) => {
        Object.keys(props.permissionsData[itm]).forEach((ele) => {
          obj2[ele] = {
            read: false,
            write: false,
          };
        });
      });
      Object.keys(permissions).forEach((itm) => {
        Object.keys(permissions[itm]).forEach((ele) => {
          obj[ele] = {
            read: permissions[itm][ele]?.find(
              (ele) => ele.action === "read" && ele.permission === true
            )
              ? true
              : false,
            write: permissions[itm][ele]?.find(
              (ele) => ele.action === "write" && ele.permission === true
            )
              ? true
              : false,
          };
        });
      });
      Object.keys(obj).forEach((ele) => {
        if (obj[ele].read) {
          initArr.push("permissionsForm_Read_" + ele);
        }
        if (obj[ele].write) {
          initArr.push("permissionsForm_Write_" + ele);
        }
      });
      form.setFields(initArr.map((ele) => ({ name: ele, value: true })));
      const arr = {
        ...obj2,
        ...obj,
      };
      const allRead = Object.keys(arr).filter((ele) => arr[ele].read);
      const allWrite = Object.keys(arr).filter((ele) => arr[ele].write);
      const keysLen = Object.keys(arr).length;
      setSelectAll((obj1) => ({
        ...obj1,
        read: allRead.length === keysLen,
        write: allWrite.length === keysLen,
      }));
      setPermissionsDataCheck(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleRolesResponse]);

  const handleSubmit = (values) => {
    const permissionsArray = [];
    const payload = {};
    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        console.log(key, value);
        permissionsArray.push(
          key.startsWith("permissionsForm_") &&
            key.replace("permissionsForm_", "")
        );
      }
    });

    ["role_name", "description"].forEach((item) => {
      payload[item] = values[item] || false;
    });
    console.log([...permissionsArray].filter((ele) => ele !== false));
    if ([...permissionsArray].filter((ele) => ele !== false).length === 0) {
      message.error(
        <span className="messageText">Permissions are required</span>
      );
      return;
    }
    ValidationSchema.validate(payload)
      .then(function ({ ...value }) {
        Object.keys(payload).forEach((key) => {
          if (!payload[key]) {
            delete payload[key];
          }
        });
        currentId
          ? dispatch(
              updateRoles({
                ...payload,
                id: currentId.toString(),
                permissions: _.uniq([...permissionsArray]).filter(Boolean),
              })
            )
          : dispatch(
              addRoles({
                ...payload,
                permissions: _.uniq([...permissionsArray]).filter(Boolean),
              })
            );
        form.resetFields();
        setPermissionsDataCheck({});
      })
      .catch(function (err) {});
  };

  const handleCancelButton = () => {
    form.resetFields(["role_name", "description"]);
    setPermissionsDataCheck({});
    props.handleCancel();
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        className="modal-form"
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t("role_name")}
          name="role_name"
          rules={[{ required: true, message: "Please enter name" }]}
        >
          <Input
            placeholder={t("role_name")}
            value={form.getFieldValue("role_name")}
            disabled={["Admin", "Employee", "Reporting Manager"].includes(
              form.getFieldValue("role_name")
            )}
          />
        </Form.Item>
        <Form.Item label={t("role_desc")} name="description">
          <Input
            placeholder={t("role_desc")}
            value={form.getFieldValue("description")}
          />
        </Form.Item>
        <div className="dividerText">{t("define_permissions")}</div>

        <Row className="mt2">
          <div className="premission-head">
            <h3 className="mb2">{t("permissions")}</h3>
            <div>
              <div className="floatRightDiv">
                <Form.Item valuePropName="checked">
                  <p className="smallP">
                    <Text>{t("select_all_write")}</Text>
                    <Checkbox
                      checked={selectAll.write}
                      onChange={(e) => {
                        let arr = { ...permissionsDataCheck };
                        Object.entries(permissionsDataCheck).forEach(
                          ([key]) => {
                            arr[key].write = e.target.checked;
                            arr[key].read = e.target.checked;
                            form.setFieldsValue({
                              [`permissionsForm_Write_${key}`]:
                                e.target.checked,
                              [`permissionsForm_Read_${key}`]: e.target.checked,
                            });
                          }
                        );
                        setPermissionsDataCheck(arr);
                        setSelectAll((obj) => ({
                          ...obj,
                          write: e.target.checked,
                          read: e.target.checked,
                        }));
                      }}
                    />
                  </p>
                </Form.Item>
              </div>
              <div className="floatRightDiv">
                <Form.Item valuePropName="checked">
                  <p className="smallP">
                    <Text>{t("select_all_read")}</Text>
                    <Checkbox
                      checked={selectAll.read}
                      disabled={selectAll.write}
                      onChange={(e) => {
                        let arr = { ...permissionsDataCheck };
                        Object.entries(permissionsDataCheck).forEach(
                          ([key]) => {
                            arr[key].read = e.target.checked;
                            form.setFieldsValue({
                              [`permissionsForm_Read_${key}`]: e.target.checked,
                            });
                          }
                        );
                        setPermissionsDataCheck(arr);
                        setSelectAll((obj) => ({
                          ...obj,
                          read: e.target.checked,
                        }));
                      }}
                    />
                  </p>
                </Form.Item>
              </div>
            </div>
          </div>
          {permissionsDataCheck &&
            Object.keys(props.permissionsData)
              ?.sort()
              ?.map((ele) => {
                return (
                  <>
                    <label className="role-section-label">
                      {ele?.replace("_", " ")}
                    </label>
                    <br />
                    {Object.entries(permissionsDataCheck).map(
                      ([key]) =>
                        props.permissionsData[ele][key] && (
                          <Col span={24} key={key}>
                            <Text>
                              <div
                                style={{
                                  display: "inline-block",
                                  maxWidth: "16rem",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                title={_.startCase(key)}
                              >
                                {_.startCase(key)}
                              </div>
                              <div className="floatRightDiv">
                                <Form.Item
                                  name={`permissionsForm_Write_${key}`}
                                  valuePropName="checked"
                                >
                                  <p className="smallP">
                                    {t("write")}
                                    <Checkbox
                                      checked={permissionsDataCheck[key].write}
                                      onChange={(e) => {
                                        let arr = { ...permissionsDataCheck };
                                        arr[key].write = e.target.checked;
                                        arr[key].read = e.target.checked;
                                        if (key === "bank_accounts") {
                                          form.setFieldsValue({
                                            [`permissionsForm_Read_account_transactions`]:
                                              e.target.checked,
                                          });
                                          form.setFieldsValue({
                                            [`permissionsForm_Write_account_transactions`]:
                                              e.target.checked,
                                          });
                                          form.setFieldsValue({
                                            [`permissionsForm_Write_statements`]:
                                              e.target.checked,
                                          });
                                          form.setFieldsValue({
                                            [`permissionsForm_Read_statements`]:
                                              e.target.checked,
                                          });
                                          form.setFieldsValue({
                                            [`permissionsForm_Write_internal_fund_transfers`]:
                                              e.target.checked,
                                          });
                                          form.setFieldsValue({
                                            [`permissionsForm_Read_internal_fund_transfers`]:
                                              e.target.checked,
                                          });
                                          arr["account_transactions"].read =
                                            e.target.checked;
                                          arr["account_transactions"].write =
                                            e.target.checked;
                                          arr["statements"].write =
                                            e.target.checked;
                                          arr["statements"].read =
                                            e.target.checked;
                                          arr["internal_fund_transfers"].write =
                                            e.target.checked;
                                          arr["internal_fund_transfers"].read =
                                            e.target.checked;
                                        }
                                        form.setFieldsValue({
                                          [`permissionsForm_Read_${key}`]:
                                            e.target.checked,
                                        });
                                        const allRead = Object.keys(arr).filter(
                                          (ele) => arr[ele].read
                                        );
                                        const allWrite = Object.keys(
                                          arr
                                        ).filter((ele) => arr[ele].write);
                                        const keysLen = Object.keys(arr).length;
                                        setPermissionsDataCheck(arr);
                                        setSelectAll((obj) => ({
                                          ...obj,
                                          read: allRead.length === keysLen,
                                          write: allWrite.length === keysLen,
                                        }));
                                      }}
                                      disabled={
                                        key === "statements" ||
                                        key === "internal_fund_transfers" ||
                                        key === "account_transactions"
                                      }
                                    />
                                  </p>
                                </Form.Item>
                              </div>
                              <div className="floatRightDiv">
                                <Form.Item
                                  name={`permissionsForm_Read_${key}`}
                                  valuePropName="checked"
                                >
                                  <p className="smallP">
                                    {t("read")}
                                    <Checkbox
                                      checked={permissionsDataCheck[key].read}
                                      disabled={
                                        permissionsDataCheck[key].write ||
                                        key === "statements" ||
                                        key === "internal_fund_transfers" ||
                                        key === "account_transactions"
                                      }
                                      onChange={(e) => {
                                        let arr = { ...permissionsDataCheck };
                                        arr[key].read = e.target.checked;
                                        if (key === "bank_accounts") {
                                          form.setFieldsValue({
                                            [`permissionsForm_Read_account_transactions`]:
                                              e.target.checked,
                                          });
                                          form.setFieldsValue({
                                            [`permissionsForm_Read_statements`]:
                                              e.target.checked,
                                          });
                                          form.setFieldsValue({
                                            [`permissionsForm_Read_internal_fund_transfers`]:
                                              e.target.checked,
                                          });
                                          arr["account_transactions"].read =
                                            e.target.checked;
                                          arr["internal_fund_transfers"].read =
                                            e.target.checked;
                                          arr["statements"].read =
                                            e.target.checked;
                                        }
                                        setPermissionsDataCheck(arr);
                                        const allRead = Object.keys(arr).filter(
                                          (ele) => arr[ele].read
                                        );
                                        const keysLen = Object.keys(arr).length;
                                        setSelectAll((obj) => ({
                                          ...obj,
                                          read: allRead.length === keysLen,
                                        }));
                                      }}
                                    />
                                  </p>
                                </Form.Item>
                              </div>
                            </Text>
                          </Col>
                        )
                    )}
                  </>
                );
              })}
        </Row>
        {/* <Collapse accordion>
            <Panel header="Admin Settings" key="7">
            </Panel>
        </Collapse> */}
        <Button
          key="1"
          htmlType="submit"
          className="formButton mt1 primaryButton"
        >
          {currentId ? t("update") : t("submit")}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => handleCancelButton()}
        >
          {t("cancel")}
        </Button>
      </Form>
    </>
  );
};

export default AddRoles;
