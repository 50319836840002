import { Input, Select, Table } from "antd";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import trash from "../../assets/icons/ocr_trash.svg";

const { Option } = Select;

const CardLimits = ({ saveLimit }) => {
  const { control, handleSubmit, register } = useForm({
    defaultValues: {
      limits: [{ amount: "", period: "Month", category: "POS" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "limits",
  });

  const onSubmit = (data) => {
    console.log("Form data: ", data);
    saveLimit()
  };

  // Define the columns for the Ant Design Table
  const columns = [
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record, index) => (
        <Input
          step="0.01"
          defaultValue={record.amount}
          {...register(`limits[${index}].amount`)}
          placeholder="0.00"
          prefix={"$"}
          bordered={false}
        />
      ),
      width: 240,
    },
    {
      title: "Per",
      dataIndex: "period",
      key: "period",
      render: (text, record, index) => (
        <Select
          bordered={false}
          {...register(`limits[${index}].period`)}
          style={{ width: "100%" }}
        >
          <Option value="Month">Month</Option>
          <Option value="Day">Day</Option>
        </Select>
      ),
      width: 240,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text, record, index) => (
        <Select
          bordered={false}
          {...register(`limits[${index}].category`)}
          style={{ width: "100%" }}
        >
          <Option value="POS">POS</Option>
          <Option value="ATM">ATM</Option>
          <Option value="Online">Online</Option>
        </Select>
      ),
      width: 240,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => (
        <button className="trash-icon">
          <img src={trash} width={12} />
        </button>
      ),
    },
  ];

  return (
    <form className="card-limits" onSubmit={handleSubmit(onSubmit)}>
      <button
        className="send-btn"
        htmlType="submit"
        style={{ right: 0, top: "-4rem", position: "absolute" }}
      >
        Save Limit
      </button>
      <Table
        columns={columns}
        dataSource={fields}
        pagination={false}
        rowKey={(record, index) => index}
        style={{
          width: "70%",
        }}
      />

      <button
        className="primary-text fs-1 mt2"
        // disabled={isAdd && !hasWriteAccess}
        onClick={() => {
          append({
            amount: "",
            period: "",
            category: "",
          });
        }}
      >
        + Add new limit
      </button>
    </form>
  );
};

export default CardLimits;
