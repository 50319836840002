import {
  BankOutlined,
  CalendarOutlined,
  FilterOutlined,
  PlusCircleFilled,
  TransactionOutlined,
} from "@ant-design/icons";
import {
  Col,
  Dropdown,
  Input,
  Menu,
  Radio,
  Row,
  DatePicker,
  Typography,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
// import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import Download from "../../assets/icons/file_download.png";
import search from "../../assets/ippo_icons/search.png";
import asc from "../../assets/ippo_icons/asc.png";
import desc from "../../assets/ippo_icons/desc.png";
import more from "../../assets/icons/more.png";
import cross from "../../assets/icons/cross.png";
import {
  get_master_account_txns,
  get_person_accounts,
} from "../../actions/z_accounts";
import NodataComponent from "./NodataComponent";
import { LeftNavContext } from "../../LeftNavProvider";
import { debounce } from "lodash";
const Text = Typography.Text;

export const sort_key = {
  from_date: "Start date",
  to_date: "End date",
  counterparty_account_name: "Recipient Name",
  account_transfer_mode_type: "Transfer Mode",
  payee_name: "Recipient Name",
  beneficiary_name: "Recipient Name",
  overparty_account_name: "From/To",
  created_at: "Created Date and Time",
  created_d_time: "Created Date and Time",
  transaction_d_time: "Posted Date and Time",
};

const BankShow = ({
  handleBack,
  noBack,
  noDownload,
  addBtn,
  text,
  setOpenAddNew,
  dates,
  accountId,
  setFilterDetails,
  setAccountObj,
  setSelectedAccount,
  callAction,
  onDownload,
  pdfLoading,
  downloadTitle,
  handleSearch,
  noPdf,
  isMore,
  handleSort,
  dotMenu,
  downloadDisable,
  filters = [],
}) => {
  const dispatch = useDispatch();
  const bankAcc = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );
  const location = useLocation();
  const history = useHistory();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortOrder, setSortOrder] = useState({});
  const [acc, setAcc] = useState("");
  const [txnType, setTxnType] = useState("");
  const [txnStatus, setTxnStatus] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(["1"]);
  const { navChildren, activeMenu, activeSubMenu } = useContext(LeftNavContext);

  useEffect(() => {
    if (dates?.[0] && dates?.[1]) {
      setStartDate(moment(dates[0]));
      setEndDate(moment(dates[1]));
    }
  }, [dates]);

  useEffect(() => {
    if (accountId) {
      setAcc(accountId);
    }
  }, [accountId]);

  useEffect(() => {
    const loadCSS = () => {
      import("react-datepicker/dist/react-datepicker.css")
        .then(() => {})
        .catch((err) => console.error("Failed to load DatePicker CSS:", err));
    };
    loadCSS();
    dispatch(
      get_person_accounts({
        page_number: 1,
        page_size: 50,
        person_id: "ID-1146",
      })
    );
  }, []);

  function handleMenuClick(e) {
    setSelectedKeys([e.key]);
  }

  const debouncedHandleSearch = debounce((value) => {
    handleSearch(value);
  }, 700);

  const dropMenu = () => {
    return (
      <div
        className="bank-menu"
        style={{ padding: "20px", backgroundColor: "#fff" }}
      >
        <Row gutter={[16]}>
          <Col span={8}>
            <Menu
              selectedKeys={selectedKeys}
              mode="inline"
              style={{ height: "100%", borderRight: 0 }}
              onClick={handleMenuClick}
            >
              <Menu.Item key="1" icon={<BankOutlined />}>
                Accounts
              </Menu.Item>
              <Menu.Item key="2" icon={<CalendarOutlined />}>
                Date
              </Menu.Item>
              <Menu.Item key="3" icon={<TransactionOutlined />}>
                Transaction Type
              </Menu.Item>
              <Menu.Item key="4" icon={<FilterOutlined />}>
                Status
              </Menu.Item>
            </Menu>
          </Col>

          {/* Main Content */}
          <Col span={16}>
            {/* Date Range Picker */}
            {selectedKeys?.includes("4") ? (
              <Radio.Group
                className="accounts-sec"
                onChange={(e) => {
                  setTxnStatus(e.target.value);
                }}
                value={txnStatus}
                style={{
                  minWidth: 350,
                }}
              >
                <Radio value="">
                  <span className="acc-num">All</span>
                </Radio>
                <Radio value="PROCESSED">
                  <span className="acc-num">Processed</span>
                </Radio>
                <Radio value="PENDING">
                  <span className="acc-num">Pending</span>
                </Radio>
                <Radio value="CANCELED">
                  <span className="acc-num">Canceled</span>
                </Radio>
                <Radio value="RETURNED">
                  <span className="acc-num">Returned</span>
                </Radio>
                <Radio value="INSERTED">
                  <span className="acc-num">Inserted</span>
                </Radio>
              </Radio.Group>
            ) : selectedKeys?.includes("3") ? (
              <Radio.Group
                className="accounts-sec"
                onChange={(e) => {
                  setTxnType(e.target.value);
                }}
                value={txnType}
                style={{
                  minWidth: 350,
                }}
              >
                <Radio value="">
                  <span className="acc-num">All Transactions</span>
                </Radio>
                <Radio value="debit">
                  <span className="acc-num">Debit</span>
                </Radio>
                <Radio value="credit">
                  <span className="acc-num">Credit</span>
                </Radio>
              </Radio.Group>
            ) : selectedKeys?.includes("1") ? (
              bankAcc?.length ? (
                <Radio.Group
                  className="accounts-sec"
                  onChange={(e) => {
                    setAcc(e.target.value);
                  }}
                  value={acc}
                >
                  <Radio
                    checked={acc === ""}
                    value={""}
                    name="account"
                    style={{
                      minWidth: 350,
                    }}
                  >
                    <span className="acc-num">All Accounts</span>
                  </Radio>
                  {bankAcc?.map((ele) => {
                    return (
                      <Radio
                        checked={acc === ele.id}
                        value={ele.id}
                        name="account"
                        style={{
                          minWidth: 350,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                          }}
                        >
                          <img
                            src={`https://flagcdn.com/${ele?.residency_country_code?.toLowerCase()}.svg`}
                            alt="US Flag"
                            width="24"
                          />
                          <span className="acc-num" title={ele?.account_name}>
                            {ele?.account_name?.length > 8
                              ? `${ele?.account_name?.substr(0, 8)}...`
                              : ele?.account_name}{" "}
                            - ****{ele?.account_number?.slice(-4)}{" "}
                          </span>
                          {/* <img src={down} width={11} /> */}
                        </div>
                      </Radio>
                    );
                  })}
                </Radio.Group>
              ) : (
                <div
                  style={{
                    minWidth: 350,
                  }}
                >
                  <NodataComponent noBtns={true} msg={"No Accounts Found"} />
                </div>
              )
            ) : (
              <>
                <Row gutter={16}>
                  <Col span={10}>
                    <Text className="label">From</Text>
                    <DatePicker
                      picker="month"
                      value={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                      }}
                      format="YYYY-MM"
                      style={{ width: "100%" }}
                      placeholder="From"
                    />
                  </Col>
                  <Col span={10}>
                    <Text className="label">To</Text>
                    <DatePicker
                      picker="month"
                      value={endDate}
                      disabledDate={(current) => {
                        // Disable all dates after maxDate
                        return current && current < startDate?.startOf("month");
                      }}
                      onChange={(date) => {
                        setEndDate(date);
                      }}
                      format="YYYY-MM"
                      style={{ width: "100%" }}
                      placeholder="To"
                    />
                  </Col>
                </Row>
                {/* <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    if (!startDate) {
                      setStartDate(date); // Update start date if not set or if the new date is earlier and no end date is selected
                    } else {
                      setEndDate(date); // Set or update the end date
                    }
                  }}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  monthsShown={2}
                  inline
                  showMonthYearPicker
                  showYearDropdown
                /> */}
                {/* <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  inline
                /> */}
              </>
            )}
            {/* Footer Buttons */}
            <div
              style={{
                display: "flex",
                justifyContent: selectedKeys?.includes("1")
                  ? "flex-end"
                  : "space-between",
                marginTop: "16px",
              }}
            >
              {/* {selectedKeys?.includes("1") ? null : (
                <div>
                  <Input
                    value={
                      startDate ? moment(startDate).format("DD/MM/YYYY") : ""
                    }
                    style={{ width: 120, marginRight: "8px" }}
                  />
                  <span
                    style={{
                      color: "#212121",
                    }}
                  >
                    →
                  </span>
                  <Input
                    value={endDate ? moment(endDate).format("DD/MM/YYYY") : ""}
                    style={{ width: 120, marginLeft: "8px" }}
                  />
                  <button
                    className="back-btn ml1"
                    onClick={() => {
                      setStartDate(null);
                      setEndDate(null);
                    }}
                    style={{
                      fontSize: "0.8rem",
                      height: 32,
                      padding: "0 1rem",
                    }}
                  >
                    Reset
                  </button>
                </div>
              )} */}
              <div className="d-flex gap1">
                <button
                  className="back-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (callAction) {
                      callAction(undefined, undefined, undefined);
                    }
                    setTxnStatus("");
                    setTxnType("");
                    setStartDate(null);
                    setEndDate(null);
                    setSelectedAccount?.("");
                    setAccountObj?.({});
                    setAcc("");
                    setOpen(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="send-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (callAction) {
                      callAction(
                        acc,
                        startDate
                          ? moment(startDate)
                              .startOf("month")
                              .format("YYYY-MM-DD")
                          : null,
                        endDate
                          ? moment(endDate).endOf("month").format("YYYY-MM-DD")
                          : null,
                        txnType,
                        txnStatus
                      );
                      if (setAccountObj)
                        setAccountObj(bankAcc?.find((ele) => ele.id === acc));
                    } else {
                      setFilterDetails?.({
                        account_id: acc,
                        // account_id: "ID-1165",
                        txnType,
                        txnStatus,
                        filters: {
                          created_at: {
                            start_date: startDate
                              ? moment(startDate)
                                  .startOf("month")
                                  .format("YYYY-MM-DD")
                              : null,
                            end_date: endDate
                              ? moment(endDate)
                                  .endOf("month")
                                  .format("YYYY-MM-DD")
                              : null,
                          },
                        },
                      });
                      setAccountObj(bankAcc?.find((ele) => ele.id === acc));
                      dispatch(
                        get_master_account_txns({
                          account_id: acc,
                          // account_id: "ID-1165",
                          filters: {
                            created_at: {
                              start_date: startDate
                                ? moment(startDate)
                                    .startOf("month")
                                    .format("YYYY-MM-DD")
                                : null,
                              end_date: endDate
                                ? moment(endDate)
                                    .endOf("month")
                                    .format("YYYY-MM-DD")
                                : null,
                            },
                          },
                        })
                      );
                    }
                    setOpen(false);
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <>
      <div className="bank-show-section">
        <div className="bank-show-container flex-between">
          <div className="v-center">
            <div className="left-side v-center">
              <Dropdown overlay={dropMenu} open={open}>
                <button
                  className="send-btn"
                  onClick={() => {
                    setOpen((b) => !b);
                  }}
                >
                  <FilterOutlined /> Add Filter
                </button>
              </Dropdown>
              <div className="bank-name">
                {filters
                  ?.filter((ele) => ele.name)
                  ?.slice(0, 2)
                  ?.map((ele) => (
                    <span className="fil-selected-btn">
                      <span>{ele.name?.toLowerCase()}</span>
                      <img
                        src={cross}
                        width={10}
                        onClick={() => {
                          if (ele.key === "txnType") {
                            setTxnType("");
                          }
                          if (ele.key === "txnStatus") {
                            setTxnStatus("");
                          }
                          ele.onClick();
                        }}
                      />
                    </span>
                  ))}
                {filters?.filter((ele) => ele.name)?.length > 2 && (
                  <span
                    className="fil-selected-btn"
                    onClick={() => {
                      setOpen((b) => !b);
                    }}
                  >
                    <span>
                      + {filters?.filter((ele) => ele.name).length - 2} More
                    </span>
                  </span>
                )}
                {callAction &&
                  filters?.filter((ele) => ele.name).length > 0 && (
                    <span
                      className="change"
                      onClick={() => {
                        if (callAction) {
                          setStartDate(null);
                          setEndDate(null);
                          setAccountObj?.({});
                          setSelectedAccount?.("");
                          setTxnStatus("");
                          setTxnType("");
                          setAcc("");
                          callAction();
                        }
                      }}
                    >
                      Clear All
                    </span>
                  )}
              </div>
            </div>
            {navChildren?.length ? (
              <div className="table tabs ">
                {navChildren?.map((ele) => {
                  return (
                    <button
                      className={`table tab ${
                        location.pathname?.includes(ele.key) ? "active" : ""
                      }`}
                      onClick={() => {
                        history.push(activeMenu + activeSubMenu + ele.key);
                      }}
                    >
                      {ele.label}
                    </button>
                  );
                })}
              </div>
            ) : null}
          </div>
          <div className="v-center">
            <div>
              <Input
                className="pp-input"
                size="large"
                id="search-input"
                placeholder="Search..."
                prefix={<img src={search} alt="" style={{ height: "14px" }} />}
                style={{
                  backgroundColor: "#FAFAFA",
                  border: "1px solid #EAEAEA",
                  borderRadius: "5px",
                  minWidth: "16rem",
                }}
                onChange={(e) => {
                  debouncedHandleSearch(e.target.value);
                }}
              />
            </div>
            <div className="right-side">
              {!noDownload && !noPdf && (
                <Dropdown
                  disabled={downloadDisable}
                  overlay={
                    <Menu>
                      <Menu.Item onClick={() => onDownload("pdf")}>
                        PDF
                      </Menu.Item>
                      <Menu.Item onClick={() => onDownload("excel")}>
                        Excel
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <button
                    className="mlHalf"
                    disabled={pdfLoading}
                    title={downloadTitle}
                  >
                    {pdfLoading ? "Downloading" : "Download"}{" "}
                    <img src={Download} alt="refresh-icon" />
                  </button>
                </Dropdown>
              )}
              {noPdf && (
                <button
                  className="mlHalf"
                  disabled={pdfLoading || downloadDisable}
                  onClick={() => {
                    onDownload("excel");
                  }}
                  title={downloadTitle}
                >
                  {pdfLoading ? "Downloading" : "Download"}{" "}
                  <img src={Download} alt="refresh-icon" />
                </button>
              )}
              {!noBack && (
                <button className="mlHalf" onClick={() => handleBack()}>
                  Back
                </button>
              )}
              {isMore && (
                <Dropdown
                  overlay={
                    <Menu>
                      <label className="dim-text ml1">Sort By</label>
                      {dotMenu?.map((ele, i) => (
                        <Menu.Item
                          key={i}
                          onClick={() => {
                            setSortOrder({
                              [ele]: sortOrder[ele] ? false : true,
                            });
                            handleSort &&
                              handleSort(ele, sortOrder[ele] ? false : true);
                          }}
                        >
                          <button className="tableFilter">
                            <span style={{ textTransform: "capitalize" }}>
                              {sort_key[ele]
                                ? sort_key[ele]
                                : ele?.replace(/_/g, " ")}
                              {sortOrder[ele] && (
                                <>
                                  &nbsp;
                                  <img src={asc} width={10} />
                                </>
                              )}
                              {sortOrder[ele] === false && (
                                <>
                                  &nbsp;
                                  <img src={desc} width={10} />
                                </>
                              )}
                            </span>
                          </button>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <button className="mlHalf">
                    <img src={more} alt="more" width={20} />
                  </button>
                </Dropdown>
              )}
            </div>
            {addBtn && (
              <button
                className="send-btn mlHalf"
                onClick={() => setOpenAddNew(true)}
              >
                <PlusCircleFilled /> {text}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default BankShow;
