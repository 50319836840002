import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { amountFormat } from '../../utils';
import { getSymbol } from '../../config/helper';

const CardPDF = ({ data }) => {
	const styles = StyleSheet.create({
		page: {
			paddingTop: 60,
			paddingLeft: 10,
			paddingRight: 10,
			lineHeight: 1.5,
			flexDirection: 'column',
		},
		mainContainer: {
			width: '100%',
			flexDirection: "column",
		},
		Headercontainer: {
			flexDirection: 'row',
			backgroundColor: '#E4E4E4',
			alignItems: 'center',
			fontStyle: 'bold',
			paddingBottom: '5px',
			paddingTop: '10px',
			paddingLeft: '15px'
		},
		text: {
			width: "12.33%",
			marginRight: "5px",
			fontSize: "9px",
			color: "#333333"
		},
		Datacontainer: {
			flexDirection: 'row',
			alignItems: 'center',
			fontStyle: 'bold',
			paddingBottom: '5px',
			paddingTop: '10px',
			paddingLeft: '15px',
			border: "1px solid #E4E4E4",
			borderRadius: '5px',
			width: '100%',
			marginTop: '5px',
		},
		data: {
			width: "12.33%",
			marginRight: "5px",
			fontSize: "9px",
			color: "#333333",
		},
	});
	const Columns = ["Card number", "Card Ref No", "Employee Name", "Email", "Balance", "Activated On", "Lock Status", "KYC Status"]
	const wrapText = (text, maxLength) => {
		if (text.length <= maxLength) return text;
		const words = text.split('');
		let wrappedText = '';
		let line = '';

		for (let i = 0; i < words.length; i++) {
			const word = words[i];
			if (line.length + word.length <= maxLength) {
				line += word + '';
			} else {
				wrappedText += line.trim() + '\n';
				line = word + '';
			}
		}

		return wrappedText + line.trim();
	};
	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<View style={styles.mainContainer}>
					<View style={styles.Headercontainer}>
						{
							Columns.map((item, index) => {
								return <Text key={index} style={styles.text}>{item}</Text>
							})
						}
					</View>
					{
						data?.map((items, index) => {
							const wrappedReference = wrapText(
								items?.card_reference_no ? items?.card_reference_no : '-',
								7 // Adjust the maximum character count as needed
							);
							return (
								<View style={styles.Datacontainer}>
									<Text style={styles.data}>{items?.card_number ? items?.card_number : "-"}</Text>
									<Text style={styles.data}>{wrappedReference}</Text>
									<Text style={styles.data}>{items?.employee_details?.name ? items?.employee_details?.name : "-"}</Text>
									<Text style={styles.data}>{items?.employee_details?.email ? items?.employee_details?.email : "-"}</Text>
									<Text style={styles.data}>{items?.balance ? getSymbol() + " " + amountFormat(items?.balance) : "-"}</Text>
									<Text style={styles.data}>{items?.card_activated_on ? items?.card_activated_on : "-"}</Text>
									<Text style={styles.data}>{items?.status ? items?.status : "-"}</Text>
									<Text style={styles.data}>{items?.kyc_flag ? items?.kyc_flag : "-"}</Text>
								</View>

							)
						})
					}
				</View>
			</Page>
		</Document>
	)
}

export default CardPDF;