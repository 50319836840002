import { Badge, Col, Row } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { headerTitles } from "../../actions/masterdata/masterdata";
import { get_all_payments } from "../../actions/z_accounts";
import credit from "../../assets/icons/credit.png";
import debit from "../../assets/icons/debit.png";
import { gridApis } from "../../config/GridApis";
import usePayAxios from "../../config/useAxios";
import BankShow from "../Common/BankShow";
import TableComponent from "../Common/TableComp/TableComp";
import { p_types } from "../Payments/SendMoney/Recipients";
import CustomStatement from "./StatementSelect";
import { saveAs } from "file-saver";
import { currencyMapper } from "../../config/helper";
import { amountFormat, removeEmptyValues } from "../../utils";
import { app_loading } from "../../actions/auth";
import NodataComponent from "../Common/NodataComponent";

const Banks = ({}) => {
  const dispatch = useDispatch();
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const { onCall, loading: pdfLoading } = usePayAxios({
    type: "grid",
    api: gridApis.fetchStatementsPDF,
    method: "post",
  });

  const [account, setAccount] = useState("");
  const [accountObj, setAccountObj] = useState({});
  const [filterValue, setFilterValue] = useState("all");
  const [search, setSearch] = useState("");
  const [filterDetails, setFilterDetails] = useState({
    date_from: null,
    date_to: null,
  });
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const data = useSelector(
    (state) => state?.z_accs?.all_payments?.data?.data?.transactions ?? []
  );
  const error = useSelector((state) => state?.z_accs?.all_payments?.data ?? []);
  const loading = useSelector((state) => state?.z_accs?.all_payments?.loading);
  const totalRecords = useSelector(
    (state) => state?.z_accs?.all_payments?.data?.total_count ?? []
  );
  useEffect(() => {
    dispatch(
      headerTitles({
        title: t("statements"),
        description: t("find_records"),
      })
    );
    return () => {
      setSearch("");
      setFilterDetails({
        date_from: null,
        date_to: null,
      });
    };
  }, []);

  const columns = [
    {
      title: t("Created Date and Time"),
      dataIndex: "created_d_time",
      key: "created_d_time",
      render: (text, rec) => (
        <div>
          {text
            ? moment(text).format("MMM DD, YYYY, hh:mm A")
            : moment(rec.created_at).format("MMM DD, YYYY, hh:mm A")}
        </div>
      ),
    },
    {
      title: t("Payment Id"),
      dataIndex: "payment_id",
      key: "payment_id",
      render: (text) => <div title={text}>{text ? text : "-"}</div>,
    },
    {
      title: t("beneficiary_name"),
      dataIndex: "counterparty_account_name",
      key: "counterparty_account_name",
      render: (text, rec) =>
        rec.transaction_type === "credit" ? rec.overparty_account_name : text,
    },
    {
      title: t("beneficiary_account"),
      dataIndex: "counterparty_account_number",
      key: "counterparty_account_number",
      render: (text) => <div title={text}>****{text?.substr(-4)}</div>,
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text, rec) => (
        <div>
          {currencyMapper[rec.currency]}
          {amountFormat(text)}
        </div>
      ),
    },
    {
      title: "Payment Method",
      dataIndex: "transfer_mode",
      key: "transfer_mode",
      render: (text) => p_types?.find((ele) => ele.value === text)?.label,
    },
    {
      title: "Txn Type",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text) => (
        <span
          style={{
            color: "#333",
            textTransform: "capitalize",
          }}
        >
          <img
            style={{
              transform: "rotate(180deg)",
            }}
            src={text === "credit" ? credit : debit}
            width={10}
          />{" "}
          {text}
        </span>
      ),
    },
    {
      title: "Posted Date and Time",
      dataIndex: "transaction_d_time",
      key: "transaction_d_time",
      render: (text) => (
        <div>{text ? moment(text).format("MMM DD, YYYY, hh:mm A") : "-"}</div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span className={`status status_${text?.toLowerCase()}`}>{text}</span>
      ),
    },
  ];

  const handleBankShowRefresh = () => {};
  return (
    <>
      {!isFilterSelected ? (
        <CustomStatement
          setSelectedAccount={setIsFilterSelected}
          account={account}
          setAccount={setAccount}
          setAccountObj={setAccountObj}
          setFilterDetails={setFilterDetails}
        />
      ) : (
        <>
          <Row wrap={false} className="table-wrapper">
            <Col flex="auto" className="table-wrapper-col">
              <div className="flex-grow">
                {filterValue === "all" ? (
                  <div className="mt1">
                    <BankShow
                      handleBankShowRefresh={handleBankShowRefresh}
                      handleBack={() => {
                        setIsFilterSelected(false);
                      }}
                      handleSearch={(val) => {
                        setSearch(val);
                        dispatch(
                          get_all_payments(
                            removeEmptyValues({
                              params: { account_id: accountObj?.id },
                              statements: true,
                              filters: {
                                created_d_time: {
                                  start_date: filterDetails.date_from,
                                  end_date: filterDetails.date_to,
                                },
                                transaction_type: filterDetails.txnType,
                                status: filterDetails.txnStatus,
                              },
                              search_key: val ? val : undefined,
                              sort: filterDetails.sort,
                            })
                          )
                        );
                      }}
                      setAccountObj={setAccountObj}
                      showClear={true}
                      filters={[
                        {
                          name:
                            filterDetails?.date_from && filterDetails?.date_to
                              ? `${moment(filterDetails?.date_from).format(
                                  "MMM/YY"
                                )} - ${moment(filterDetails?.date_to).format(
                                  "MMM/YY"
                                )}`
                              : ``,
                          onClick: () => {
                            dispatch(
                              get_all_payments(
                                removeEmptyValues({
                                  params: {
                                    account_id: accountObj?.id,
                                  },
                                  sort: filterDetails.sort,
                                  statements: true,
                                  filters: {
                                    transaction_type: filterDetails.txnType,
                                    status: filterDetails.txnStatus,
                                  },
                                })
                              )
                            );

                            setFilterDetails((val) => ({
                              ...val,
                              date_from: null,
                              date_to: null,
                            }));
                          },
                        },
                        {
                          name: accountObj?.account_name
                            ? `${
                                accountObj?.account_name
                              }-**${accountObj?.account_number?.slice(-4)}`
                            : "",
                          onClick: () => {
                            dispatch(
                              get_all_payments(
                                removeEmptyValues({
                                  params: {},
                                  sort: filterDetails.sort,
                                  statements: true,
                                  filters: {
                                    created_d_time: {
                                      start_date: filterDetails.date_from,
                                      end_date: filterDetails.date_to,
                                    },
                                    transaction_type: filterDetails.txnType,
                                    status: filterDetails.txnStatus,
                                  },
                                })
                              )
                            );
                            setAccountObj({});
                          },
                        },
                        {
                          name: filterDetails.txnType,
                          onClick: () => {
                            dispatch(
                              get_all_payments(
                                removeEmptyValues({
                                  params: {
                                    account_id: accountObj?.id
                                      ? accountObj?.id
                                      : filterDetails.acc,
                                  },
                                  sort: filterDetails.sort,
                                  statements: true,
                                  filters: {
                                    created_d_time: {
                                      start_date: filterDetails.date_from,
                                      end_date: filterDetails.date_to,
                                    },
                                    status: filterDetails.txnStatus,
                                  },
                                })
                              )
                            );

                            setFilterDetails((val) => ({
                              ...val,
                              txnType: "",
                            }));
                          },
                          key: "txnType",
                        },
                        {
                          name: filterDetails.txnStatus,
                          onClick: () => {
                            dispatch(
                              get_all_payments(
                                removeEmptyValues({
                                  params: {
                                    account_id: accountObj?.id
                                      ? accountObj?.id
                                      : filterDetails.acc,
                                  },
                                  sort: filterDetails.sort,
                                  statements: true,
                                  filters: {
                                    created_d_time: {
                                      start_date: filterDetails.date_from,
                                      end_date: filterDetails.date_to,
                                    },
                                    transaction_type: filterDetails.txnType,
                                  },
                                })
                              )
                            );

                            setFilterDetails((val) => ({
                              ...val,
                              txnStatus: "",
                            }));
                          },
                          key: "txnStatus",
                        },
                      ]}
                      downloadDisable={error?.error || !accountObj?.id}
                      downloadTitle={
                        !accountObj?.id
                          ? "Please select an account to download"
                          : ""
                      }
                      setFilterDetails={setFilterDetails}
                      callAction={(acc, s_date, e_date, txnType, txnStatus) => {
                        if (
                          !acc &&
                          !s_date &&
                          !e_date &&
                          !txnType &&
                          !txnStatus
                        ) {
                          dispatch(
                            get_all_payments({
                              statements: true,
                            })
                          );
                          setFilterDetails({
                            date_from: null,
                            date_to: null,
                          });
                        } else {
                          dispatch(
                            get_all_payments(
                              removeEmptyValues({
                                params: { account_id: acc },
                                statements: true,
                                filters: {
                                  created_d_time: {
                                    start_date: s_date,
                                    end_date: e_date,
                                  },
                                  transaction_type: txnType,
                                  status: txnStatus,
                                },
                              })
                            )
                          );
                          setFilterDetails({
                            date_from: s_date ? s_date : null,
                            date_to: e_date ? e_date : null,
                            txnType,
                            txnStatus,
                          });
                        }
                      }}
                      accountId={accountObj?.id}
                      dates={[filterDetails?.date_from, filterDetails?.date_to]}
                      pdfLoading={pdfLoading}
                      onDownload={(type) => {
                        dispatch(app_loading(true));
                        onCall({
                          params: {
                            account_id: accountObj?.id,
                            format: type,
                          },
                          data: {
                            account_id: accountObj?.id,
                            statement_start_date: moment(
                              filterDetails?.date_from
                            ).format("YYYY-MM-DD"),
                            statement_end_date: moment(
                              filterDetails?.date_to
                            ).format("YYYY-MM-DD"),
                          },
                        }).then(async (res) => {
                          if (res?.data?.statement_file_url) {
                            const blob = await fetch(
                              res?.data?.statement_file_url
                            ).then((res) => res.blob());
                            saveAs(blob, res?.data?.file_name);
                          }
                          dispatch(app_loading(false));
                        });
                      }}
                      dotMenu={[
                        "amount",
                        "counterparty_account_name",
                        "created_d_time",
                        // "transaction_d_time",
                      ]}
                      isMore={true}
                      handleSort={(val, sort) => {
                        setFilterDetails((fltDetails) => ({
                          ...fltDetails,
                          sort: {
                            sort_key: val,
                            sort_order: sort ? -1 : 1,
                          },
                        }));
                        dispatch(
                          get_all_payments(
                            removeEmptyValues({
                              params: {
                                account_id: accountObj?.id
                                  ? accountObj?.id
                                  : filterDetails.acc,
                              },
                              search_key: search,
                              page_number: 1,
                              statements: true,
                              sort: {
                                sort_key: val,
                                sort_order: sort ? -1 : 1,
                              },
                              filters: {
                                created_d_time: {
                                  start_date: filterDetails.date_from,
                                  end_date: filterDetails.date_to,
                                },
                              },
                            })
                          )
                        );
                      }}
                    />
                  </div>
                ) : null}

                <div
                  className={loading ? "shimmer" : ""}
                  style={{ paddingTop: "15px" }}
                >
                  <TableComponent
                    columns={columns}
                    scroll={{ x: "max-content" }}
                    loading={loading}
                    data={Array.isArray(data) ? data : []}
                    hasCheckbox={false}
                    rowKey="bill_no"
                    pagination={{
                      total: totalRecords || 0,
                      current: page,
                      onChange: (pagee) => {
                        dispatch(
                          get_all_payments(
                            removeEmptyValues({
                              params: {
                                account_id: accountObj?.id,
                                page_number: pagee,
                              },
                              statements: true,
                              filters: {
                                created_d_time: {
                                  start_date: filterDetails.date_from,
                                  end_date: filterDetails.date_to,
                                },
                                transaction_type: filterDetails.txnType,
                                status: filterDetails.txnStatus,
                              },
                              search_key: search ? search : undefined,
                              sort: filterDetails.sort,
                            })
                          )
                        );
                        setPage(pagee);
                      },
                    }}
                    renderEmpty={
                      error?.error
                        ? () => <NodataComponent msg={error?.message} noBtns />
                        : undefined
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default Banks;
