import { Button, message } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { apis } from "../../../../config/APIs";
import useAdminAxios from "../../../../config/useAxios";
import { FormInput, FormSelect } from "../../../inputs";
const UserBusinessDetailsForm = ({
  register,
  control,
  errors,
  onSuccess,
  handleSubmit,
  pageCount,
  allCurrencyList,
  allCountries
}) => {
  const { onCall: createAccount, loading } = useAdminAxios({
    api: apis.onboardPostAPI,
    method: "post",
  });
  const { t } = useTranslation();
  const onSubmit = (data) => {
    data.business_email = data.email;
    data.step = 2;
    createAccount({
      data,
    })
      .then((customer) => {
        if (customer.error === false) {
          onSuccess();
        }
        if (customer.error) {
          message.error(
            <span className="messageText">{customer?.message}</span>
          );
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          message.error(
            <span className="messageText">{err?.response?.data?.message}</span>
          );
        }
      });
  };
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pp-form-item field_length">
          <FormInput
            inline
            control={control}
            {...register("business_name", {
              equired: pageCount === 3 ? "Business Name is required." : false,
            })}
            errors={errors}
            label="Business Name"
            placeholder="Enter Business Name"
            required={pageCount === 3}
          />
        </div>
        {/* <div className="pp-form-item field_length">
          <FormInput
            inline
            control={control}
            {...register("business_location", {
              required: pageCount === 3 ? "Business registered is required." : false,
            })}
            errors={errors}
            label="Where is your business registered?"
            placeholder="Enter business registered"
            required={pageCount === 3}
          />
        </div> */}
        <div className="pp-form-item field_length">
          <FormSelect
            inline
            control={control}
            {...register("business_location", {
              required: pageCount === 3 ? "Business registered is required." : false,
            })}
            errors={errors}
            label="Where is your business registered?"
            placeholder={t('select')}
            options={allCountries}
            hideCreateOption={true}
            required={pageCount === 3}
            filterKey={"country_name"}
          />
        </div>
        <div className="pp-form-item field_length prefix">
          <FormInput
            inline
            control={control}
            {...register("primary_phone", {
              required: pageCount === 3 ? "Office Phone Number is required." : false,
            })}
            errors={errors}
            required={pageCount === 3}
            label="Office Phone Number"
            placeholder="Enter Mobile Number"
            // prefix={"+91"}
            number={true}
          />
        </div>
        <div className="pp-form-item field_length">
          <FormSelect
            inline
            control={control}
            {...register("currency_id", {
              required: pageCount === 3 ? `${t('base_currency')} is required` : false
            })}
            errors={errors}
            label={t('base_currency')}
            placeholder={t('select')}
            options={allCurrencyList}
            hideCreateOption={true}
            required={pageCount === 3}
            filterKey={"country_name"}
          />
        </div>
        <div className={`pp-form-item field_length mt2`}>
          <Button
            type="primary"
            loading={loading}
            className="send-btn"
            id="business"
            htmlType="submit"
          >
            Next
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default UserBusinessDetailsForm;
