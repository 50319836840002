import { Checkbox, Col, Form, Input, Row, Select, Tabs } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import excel_icon from "../../../../assets/icons/excel1.png";
import pdf_icon from "../../../../assets/icons/pdf_icon.png";
import ppt_icon from "../../../../assets/icons/ppticon.png";
import { apis } from "../../../../config/APIs";
import usePayAxios from "../../../../config/useAxios";
import { countries } from "../../../OnBoarding/Auth/SignUpFrom/UserDetailsForm";
import { countryValidations } from "../../Organisation/country";
import {
  GetSingleRoles,
  getSingleRoles,
} from "../../../../actions/companySettings";
const { Option } = Select;

const AddUserFirstStep = ({
  form,
  validateMessages1,
  handleSubmit1,
  editValues,
  projectsResponse,
  handleDesignation,
  approversData,
  loading,
  advanceApproversData,
  rolesData,
  costCentersResponse,
  userfieldsData,
  mobile,
  handleBackButton,
  renderDataTypes,
  setMobile,
  ...props
}) => {
  const { t } = useTranslation();
  const { onCall, data, reset } = usePayAxios({
    api: apis.userNameFind,
    method: "get",
  });
  const dispatch = useDispatch();
  const [accountIds, setAccountIds] = useState([]);
  const [hasAccount, setHasAccount] = useState(false);
  const singleRolesResponse = useSelector(
    (state) => state.companySetting.getSingleRoles || {}
  );

  const { data: { data: { permissions = {} } = {} } = {} } =
    singleRolesResponse;
  const deleteEmployeeResponse = useSelector(
    (state) => state?.employees?.deleteEmployeeDocument
  );
  const bankAcc = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );

  useEffect(() => {
    if (editValues?.account_info?.length) {
      setAccountIds(editValues?.account_info?.map((ele) => ele.id));
    }
  }, [editValues]);

  useEffect(() => {
    if (editValues?.role_info?.length) {
      editValues?.role_info?.forEach((ele) => {
        if (ele.role_name !== "Employee") {
          dispatch(getSingleRoles({ role_id: ele.id }));
        }
      });
    }
  }, [editValues]);

  const debouncedOnChange = debounce((e) => {
    onCall({
      params: {
        username: e.target.value,
      },
    }).catch(() => {
      const t = setTimeout(() => {
        reset();
        clearTimeout(t);
      }, 5000);
    });
  }, 500);

  const hasWritePermission = (permissions, category, sub_cat, action) => {
    return (
      permissions[category][sub_cat]?.filter(
        (permission) => permission.action === action && permission.permission
      ).length > 0
    );
  };

  useEffect(() => {
    if (Object.keys(permissions)?.length) {
      const canWriteBankAccounts = hasWritePermission(
        permissions,
        "accounts",
        "bank_accounts",
        "read"
      );
      setHasAccount(canWriteBankAccounts);
      if (!canWriteBankAccounts) {
        form.setFields([
          {
            name: "account_ids",
            value: [],
          },
        ]);
        setAccountIds([]);
      }
      dispatch({
        type: GetSingleRoles.RESET,
      });
    }
  }, [permissions]);

  const role = form.getFieldValue("roles");

  return (
    <React.Fragment>
      <Form
        onSubmitCapture={() => {
          form
            .validateFields()
            .then((res) => {
              handleSubmit1(res);
            })
            .catch(() => {});
        }}
        form={form}
        name="step1"
        layout="vertical"
        className="modal-form"
        validateMessages={validateMessages1}
      >
        <Row>
          <Col span={16}>
            <Form.Item
              name="title"
              label={t("title")}
              className="titleItem"
              rules={[
                {
                  required: true,
                  message: t("title_required"),
                },
              ]}
            >
              <Select
                value="Mr"
                className="pp-dropdown-inline title-select"
                style={{ width: 80 }}
                placeholder="Mr."
                onChange={(e) => {
                  form.setFields([
                    {
                      name: "title",
                      value: e,
                    },
                  ]);
                }}
              >
                <Option value="Mr">{t("mr")}</Option>
                <Option value="Mrs">{t("mrs")}</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="name"
              label={t("name")}
              className="nameItem"
              rules={[{ required: true }]}
            >
              <Input
                className="pp-form-input"
                placeholder={t("enter_first_name")}
                onChange={(e) => {
                  form.setFields([
                    {
                      name: "name",
                      value: e.target.value,
                    },
                  ]);
                }}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label={t("email")}
              rules={[
                {
                  type: "email",
                  message: t("valid_email"),
                },
                {
                  required: true,
                  message: t("input_email"),
                },
              ]}
            >
              <Input
                placeholder={t("enter_email")}
                className="pp-form-input"
                disabled={editValues?.email}
                onChange={(e) => {
                  form.setFields([
                    {
                      name: "email",
                      value: e.target.value,
                    },
                  ]);
                }}
              />
            </Form.Item>
            <Form.Item
              name="username"
              label={t("Username")}
              rules={[
                {
                  required: true,
                  message: t("Username is required"),
                },
              ]}
            >
              <Input
                placeholder={t("Enter Username")}
                className="pp-form-input"
                defaultValue={editValues?.username}
                disabled={editValues?.username}
                onChange={(e) => {
                  debouncedOnChange(e);
                  form.setFields([
                    {
                      name: "username",
                      value: e.target.value,
                    },
                  ]);
                }}
              />
              {data?.response?.data?.message ? (
                <div
                  className="error"
                  style={{
                    marginTop: 10,
                  }}
                >
                  {data?.response?.data?.message}
                </div>
              ) : null}
            </Form.Item>
            <Form.Item
              name="emp_id"
              label={t("employee_id")}
              rules={[{ required: true }]}
            >
              <Input
                placeholder={t("enter_employee_id")}
                className="pp-form-input"
                // disabled={props.selectedId && editValues?.emp_id}
                disabled={editValues?.emp_id}
                onChange={(e) => {
                  form.setFields([
                    {
                      name: "emp_id",
                      value: e.target.value,
                    },
                  ]);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              label={t("mobile_number")}
              name="primary_phone"
              rules={
                countryValidations.find(
                  (ele) => ele.code === form.getFieldValue("phone_country_code")
                )?.phone_validation
                  ? [
                      { required: true, message: t("please_input") },
                      {
                        pattern: countryValidations.find(
                          (ele) =>
                            ele.code ===
                            form.getFieldValue("phone_country_code")
                        ).phone_validation,
                        message: t("Enter Valid Phone number"),
                      },
                    ]
                  : [{ required: true, message: t("please_input") }]
              }
            >
              <span className="ant-input-group-wrapper">
                <span className="ant-input-wrapper ant-input-group mobile_number">
                  <Select
                    className="pp-dropdown pp-dropdown-inline"
                    disabled={editValues?.primary_phone}
                    options={countries?.map((item) => ({
                      value: item?.dial_code,
                      dial_code: item?.dial_code,
                      label: (
                        <span
                          style={{
                            color: "#212121",
                            fontSize: 13,
                          }}
                        >
                          <img
                            src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${item?.code?.toLowerCase()}.svg`}
                            className="mrHalf"
                            width={20}
                            style={{
                              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
                            }}
                          />
                          {`(${item?.dial_code})`}
                        </span>
                      ),
                    }))}
                    onChange={(e) => {
                      form.setFields([
                        {
                          name: "phone_country_code",
                          value: e,
                        },
                      ]);
                    }}
                    filterKey={"dial_code"}
                    showSearch
                    value={form.getFieldValue("phone_country_code")}
                  />
                  <input
                    disabled={editValues?.primary_phone}
                    placeholder={t("enter_num")}
                    maxlength="10"
                    type="text"
                    className="ant-input pp-form-input"
                    value={mobile}
                    onChange={(e) => {
                      if (new RegExp(/^[0-9]*$/).test(e.target.value)) {
                        setMobile(e.target.value);
                        form.setFields([
                          {
                            name: "primary_phone",
                            value: e.target.value,
                          },
                        ]);
                      } else if (e.target.value === "") {
                        setMobile(e.target.value);
                      }
                    }}
                  />
                </span>
              </span>
            </Form.Item>
          </Col>
          <Col span={8} className="ml1">
            <Form.Item name="roles" label="Role" rules={[{ required: true }]}>
              <Select
                className="pp-dropdown-inline title-select"
                placeholder={t("select_role")}
                // mode="multiple"
                maxTagCount={2}
                onChange={(e, rec) => {
                  if (rec?.key) dispatch(getSingleRoles({ role_id: rec?.key }));
                  if (e === "Admin") {
                    form.setFields([
                      {
                        name: "account_ids",
                        value: bankAcc?.map((ele) => ele.id),
                      },
                    ]);
                    setAccountIds(bankAcc?.map((ele) => ele.id));
                  }
                  form.setFields([
                    {
                      name: "roles",
                      value: [e],
                    },
                  ]);
                }}
              >
                {rolesData &&
                  rolesData
                    ?.filter(
                      (role) =>
                        role.role_name !== "Employee" &&
                        role.role_name !== "Reporting Manager"
                    )
                    .map(({ id, role_name }) => (
                      <Option key={id} value={role_name}>
                        {role_name}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {/* <Row>
          <Col span={6}>
            <Form.Item name="project_id" label={t("project")}>
              <Select
                className="pp-dropdown-inline title-select"
                placeholder={t("select_project")}
                value={form.getFieldValue("project_id")}
              >
                {projectsResponse &&
                  projectsResponse.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} className="ml1">
            <Form.Item name="cost_center_id" label={t("cost_center")}>
              <Select
                className="pp-dropdown-inline title-select"
                placeholder={t("select_cost_center")}
                value={form.getFieldValue("cost_center_id")}
              >
                {costCentersResponse &&
                  costCentersResponse.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row> */}
        <Row>
          {approversData &&
            approversData?.map(({ role_name, options }, index) => {
              return (
                <Col span={8} key={index}>
                  <Form.Item
                    label={role_name}
                    name={role_name}
                    className="dynamicValues"
                  >
                    <Select
                      placeholder={t("select_approver")}
                      className="pp-dropdown-inline title-select"
                      value={form?.getFieldValue(role_name)}
                    >
                      {options &&
                        options.map(({ id, name, first_name }) => (
                          <Option key={id} value={id}>
                            {name ?? first_name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              );
            })}
        </Row>

        <Row className="w100">
          {userfieldsData &&
            userfieldsData?.map(
              ({
                field_id,
                display_name,
                column_name,
                is_required,
                column_type,
                data,
                default_values,
                selected,
              }) => (
                <Col span={8} key={display_name}>
                  <Form.Item
                    name={field_id}
                    label={display_name}
                    className="dynamicValues"
                    rules={
                      column_type === "integer"
                        ? [
                            {
                              required: is_required,
                              message: `${display_name} is required`,
                            },
                            {
                              pattern: /^[0-9]*$/,
                              message: " Only Numbers are allowed",
                            },
                          ]
                        : [
                            {
                              required: is_required,
                              message: `${display_name} is required`,
                            },
                          ]
                    }
                  >
                    {renderDataTypes(
                      column_type,
                      default_values,
                      field_id,
                      data,
                      selected?.value,
                      selected?.attachments?.map((ele) => ({
                        ...ele,
                        name: ele.filename,
                        url: ele?.key,
                        uid: ele.filename,
                        thumbUrl: ele?.content_type?.includes("image/")
                          ? null
                          : ele.content_type === "application/pdf"
                          ? pdf_icon
                          : ele?.content_type ===
                            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                          ? ppt_icon
                          : excel_icon,
                      }))
                    )}
                  </Form.Item>
                </Col>
              )
            )}
        </Row>
        {hasAccount && (
          <Row style={{ opacity: role?.[0] === "Admin" ? 0.8 : 1 }}>
            <Col>
              <div className="acc_access mt2">
                <div className="title-sec w70">
                  <label>Access accounts</label>
                  <span>
                    Team members must have access to at least one checking
                    account.
                  </span>
                </div>
                <div>
                  {role?.[0] === "Admin" ? null : (
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (accountIds?.length === bankAcc?.length) {
                          form.setFields([
                            {
                              name: "account_ids",
                              value: [],
                            },
                          ]);
                          setAccountIds([]);
                        } else {
                          form.setFields([
                            {
                              name: "account_ids",
                              value: bankAcc?.map((ele) => ele.id),
                            },
                          ]);
                          setAccountIds(bankAcc?.map((ele) => ele.id));
                        }
                      }}
                    >
                      {accountIds?.length === bankAcc?.length
                        ? "Unselect"
                        : "Select"}{" "}
                      All
                    </span>
                  )}
                </div>
              </div>
              {bankAcc?.map((ele) => {
                return (
                  <div>
                    <label htmlFor={ele.account_name}>
                      {ele.account_name} - ****{ele?.account_number?.slice(-4)}
                      <Checkbox
                        name={ele.account_name}
                        id={ele.account_name}
                        checked={accountIds?.includes(ele.id)}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          const currentIds =
                            form.getFieldValue("account_ids") || [];
                          let updatedIds;

                          if (isChecked) {
                            // Add the ID if it's checked
                            updatedIds = [...currentIds, ele.id];
                          } else {
                            // Remove the ID if it's unchecked
                            updatedIds = currentIds.filter(
                              (id) => id !== ele.id
                            );
                          }
                          setAccountIds(updatedIds);
                          form.setFields([
                            {
                              name: "account_ids",
                              value: updatedIds,
                            },
                          ]);
                        }}
                        disabled={role?.[0] === "Admin"}
                      />
                    </label>
                  </div>
                );
              })}
            </Col>
          </Row>
        )}
        <button
          key="1"
          className="send-btn mt1 "
          htmlType="submit"
          disabled={loading || deleteEmployeeResponse.loading}
        >
          {t("Save")}
        </button>
        <button
          key="2"
          className="back-btn ml1 mt1"
          onClick={() => {
            form.resetFields();
            props.handleAdd();
            // setImage([])
          }}
        >
          {t("cancel")}
        </button>
      </Form>
    </React.Fragment>
  );
};

export default AddUserFirstStep;
