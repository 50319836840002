import { Col, Row, Switch } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CardSettingsAccordion from "./cardSettingsAccordion";
import AdvanceCardControls from "./AdvancedControls";

const CardSettings = ({
  hasWriteAccess,
  cardData,
  setSelectedRow,
  setEditRow,
  card_data,
  cardEnalbeLimit,
  setIsModalVisible,
  card_id,
  txnEnable,
  setTxnEnable,
  editRow,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <section>
      <div className="setting_header">
        <div className="m1 p1 other-info">
          <h4 className="theader">{t("card_usage_controls")}</h4>
          <Row
            className="flex-wrap"
            style={{
              marginBottom: "2rem",
              marginTop: "2.46rem",
            }}
          >
            <Col span={7} className="v-center card_switch_button">
              <p className="d-flex flex-col" style={{ marginLeft: "1.75rem" }}>
                <label>{t("pos_trans")}</label>
                <small>{t("enable_pos_trans")}</small>
              </p>
              <Switch
                disabled={!hasWriteAccess}
                className="switch"
                checked={txnEnable["POS"]}
                onChange={(val) => {
                  setTxnEnable((prev) => ({
                    ...prev,
                    POS: val,
                  }));
                  dispatch(
                    cardEnalbeLimit({
                      card: {
                        card_id,
                        transaction_type: "POS",
                        is_enabled: val,
                      },
                    })
                  );
                }}
              />
            </Col>
            <Col span={7} className="v-center card_switch_button">
              <p className="d-flex flex-col" style={{ marginLeft: "1.75rem" }}>
                <label>{t("Domestic ATM withdrawal")}</label>
                <small>{t("enable_online_transactions")}</small>
              </p>
              <Switch
                disabled={!hasWriteAccess}
                className="switch"
                checked={txnEnable["ATM"]}
                onChange={(val) => {
                  setTxnEnable((prev) => ({
                    ...prev,
                    ATM: val,
                  }));
                  dispatch(
                    cardEnalbeLimit({
                      card: {
                        card_id,
                        transaction_type: "ATM",
                        is_enabled: val,
                      },
                    })
                  );
                }}
              />
            </Col>
            <Col span={7} className="v-center card_switch_button">
              <p className="d-flex flex-col" style={{ marginLeft: "1.75rem" }}>
                <label>{t("online_transactions")}</label>
                <small>{t("enable_online_transactions")}</small>
              </p>
              <Switch
                disabled={!hasWriteAccess}
                className="switch"
                checked={txnEnable["ECOM"]}
                onChange={(val) => {
                  setTxnEnable((prev) => ({
                    ...prev,
                    ECOM: val,
                  }));
                  dispatch(
                    cardEnalbeLimit({
                      card: {
                        card_id,
                        transaction_type: "ECOM",
                        is_enabled: val,
                      },
                    })
                  );
                }}
              />
            </Col>
            <Col span={7} className="v-center card_switch_button">
              <p className="d-flex flex-col" style={{ marginLeft: "1.75rem" }}>
                <label>{t("International ATM withdrawals")}</label>
                <small>{t("enable_online_transactions")}</small>
              </p>
              <Switch
                disabled={!hasWriteAccess}
                className="switch"
                checked={txnEnable["ECOM"]}
                onChange={(val) => {
                  setTxnEnable((prev) => ({
                    ...prev,
                    ECOM: val,
                  }));
                  dispatch(
                    cardEnalbeLimit({
                      card: {
                        card_id,
                        transaction_type: "ECOM",
                        is_enabled: val,
                      },
                    })
                  );
                }}
              />
            </Col>
            <Col span={7} className="v-center card_switch_button">
              <p className="d-flex flex-col" style={{ marginLeft: "1.75rem" }}>
                <label>{t("Cross Border Transactions")}</label>
                <small>{t("enable_online_transactions")}</small>
              </p>
              <Switch
                disabled={!hasWriteAccess}
                className="switch"
                checked={txnEnable["ECOM"]}
                onChange={(val) => {
                  setTxnEnable((prev) => ({
                    ...prev,
                    ECOM: val,
                  }));
                  dispatch(
                    cardEnalbeLimit({
                      card: {
                        card_id,
                        transaction_type: "ECOM",
                        is_enabled: val,
                      },
                    })
                  );
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      <hr />
      <div className="setting_body">
        <div className={`m1 p1 other-info`}>
          <h4 className="header">{t("card_limit")}</h4>
          <CardSettingsAccordion
            cardType="card_setting_page"
            card_ref_number={card_data?.card_ref_number}
            card_id={card_data?.id}
            editRow={editRow}
            setEditRow={setEditRow}
            hasWriteAccess={hasWriteAccess}
            // channels={cardData?.data?.card_limits?.map(
            //   (row) => row.channelType
            // )}
            // card_limits={cardData?.data?.card_limits}
            onEdit={() => {
              let card_limit = cardData?.data?.card_limits?.map((row) => {
                let arr = [];
                if (row.currentDailyLimit) {
                  arr.push({
                    transaction_type: row.channelType,
                    amount: row.currentDailyLimit,
                    frequency: "DAILY",
                  });
                }
                if (row.currentMonthlyLimit) {
                  arr.push({
                    transaction_type: row.channelType,
                    amount: row.currentMonthlyLimit,
                    frequency: "MONTHLY",
                  });
                }
                if (row.currentAnnualLimit) {
                  arr.push({
                    transaction_type: row.channelType,
                    amount: row.currentAnnualLimit,
                    frequency: "ANNUALLY",
                  });
                }
                return arr;
              });
              let arr = [];
              card_limit.forEach((ele) => {
                arr = [...arr, ...ele];
              });

              setSelectedRow({
                id: card_data?.id,
                channel_enabled: [
                  {
                    POS: cardData?.data?.channelType?.includes("POS"),
                  },
                  {
                    ATM: cardData?.data?.channelType?.includes("ATM"),
                  },
                  {
                    ECOM: cardData?.data?.channelType?.includes("ECOM"),
                  },
                ],
                assigned_data: [],
                card_limit: arr,
              });
              setIsModalVisible(true);
            }}
            onAdd={() => {
              setIsModalVisible(true);
            }}
          />
        </div>
        <hr />
        <div className={`m1 p1 other-info`}>
          <h4 className="header">{t("Advance Card Controls")}</h4>
          <AdvanceCardControls />
        </div>
      </div>
    </section>
  );
};

export default CardSettings;
