import { Divider } from "antd";
import React from "react";

const walletsData = [
  {
    title: "Expense Wallet",
    items: [{ icon: "💼", label: "Expense", amount: 11200.0 }],
  },
  {
    title: "Reimbursement Wallet",
    items: [{ icon: "🔄", label: "Reimbursement", amount: 5563.0 }],
  },
  {
    title: "Benefits Wallet",
    items: [
      { icon: "🍽️", label: "Meal", amount: 1200.0 },
      { icon: "⛽", label: "Fuel", amount: 4300.0 },
      { icon: "✈️", label: "Travel", amount: 8000.0 },
      { icon: "📚", label: "Books & Periodicals", amount: 9450.0 },
      { icon: "🎁", label: "Gift", amount: 1200.0 },
    ],
  },
];

const WalletBalances = () => {
  return (
    <div className="wallet-container">
      {walletsData.map((wallet, index) => (
        <div key={index} className="wallet-section">
          <h4>{wallet.title}</h4>
          <Divider />
          {wallet.items.map((item, idx) => (
            <div key={idx} className="wallet-item">
              <div className="wallet-item-left">
                <span className="wallet-icon">{item.icon}</span>
                <span>{item.label}</span>
              </div>
              <div className="wallet-item-right">
                $
                {item.amount.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default WalletBalances;
