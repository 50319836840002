import React from "react";
import { countries } from "../Settings/Organisation/country";

const CountryComponent = ({ country_code }) => {
  return (
    <>
      {countries?.find((ele) => ele.code === country_code)?.label}{" "}
      <img
        src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${country_code?.toLowerCase()}.svg`}
        // className="mr1"
        width={25}
        style={{
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
        }}
      />
    </>
  );
};

export default CountryComponent;
