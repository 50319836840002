import { takeLatest } from "redux-saga/effects";
import { gridApis } from "../../config/GridApis";
import { sagaFunctionsGrid } from "../../utils";
import {
  GetMasterAccountTxns,
  GetPayments,
  GetPersonAccounts,
  GetPurposes,
  InitializePayment,
} from "./accounts";

function getAccounts(req) {
  const params = req?.payload?.params;
  delete req?.payload?.params;
  return sagaFunctionsGrid(
    GetPersonAccounts,
    "post",
    `${gridApis.getAllAccounts}`,
    req.payload,
    {},
    params
  )();
}

function getPurposes(req) {
  return sagaFunctionsGrid(
    GetPurposes,
    "get",
    `${gridApis.getPaymentPurposes}`,
    req.payload
  )();
}

function getPayments(req) {
  const params = req?.payload?.params;
  delete req?.payload?.params;

  const txns = req.payload?.txns;
  const isSubAcc = req.payload?.isSubAcc;
  const statements = req.payload?.statements;
  delete req?.payload?.txns;
  delete req?.payload?.statements;
  delete req?.payload?.isSubAcc;
  const request = isSubAcc
    ? gridApis.fetchSubAccountTransactions
    : statements
    ? gridApis.fetchStatements
    : txns
    ? gridApis.fetchAccountTransactions
    : gridApis.getAllPayments;

  return sagaFunctionsGrid(
    GetPayments,
    "post",
    `${request}`,
    req.payload,
    {},
    params
  )();
}

function getAccountTxns(req) {
  const acc_id = req?.payload?.account_id;
  delete req?.payload?.account_id;
  return sagaFunctionsGrid(
    GetMasterAccountTxns,
    "get",
    `${gridApis.masterAccountTransactions}?${
      acc_id ? "account_id=" + acc_id : ""
    }`,
    req.payload
  )();
}

function initializePayment(req) {
  return sagaFunctionsGrid(
    InitializePayment,
    "post",
    `${gridApis.initialisePayment}`,
    req.payload
  )();
}

export function* z_accountsWatcher() {
  yield takeLatest(GetPersonAccounts.REQUEST, getAccounts);
  yield takeLatest(GetMasterAccountTxns.REQUEST, getAccountTxns);
  yield takeLatest(InitializePayment.REQUEST, initializePayment);
  yield takeLatest(GetPurposes.REQUEST, getPurposes);
  yield takeLatest(GetPayments.REQUEST, getPayments);
}
