import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import u_edit from "../RequestMoney/assets/u_edit.png";
import bank_icon from "../../../assets/icons/bank_icon.png";
import { FormSelect } from "../../inputs";
import CreateManageBeneficiary from "../../Common/CreateManageBeneficiary";
export const p_types = [
  {
    label: "Within Zenus",
    value: "WITHIN_BANK",
  },
  {
    label: "ACH - Local Transfer",
    value: "LOCAL_US_ACH",
  },
  {
    label: "International Wire (SWIFT)",
    value: "SWIFT_CROSSBORDER",
  },
];

const RecipientsForm = ({ next, setDBAcc, onSubmit: onSub, acc, mainData }) => {
  const [openAddNew, setOpenAddNew] = useState(false);
  const [recpientId, setRecipientId] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    watch,
    setValue,
    setError,
  } = useForm();

  const currenciesRes = useSelector((state) =>
    state?.currencies?.data?.data?.length
      ? state?.currencies?.data?.data?.map((ele) => ({
          label: (
            <span
              style={{
                color: "#212121",
                fontSize: 15,
              }}
            >
              <img
                src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${ele?.country_code?.toLowerCase()}.svg`}
                className="mr1"
                width={25}
                style={{
                  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
                }}
              />
              {`${ele?.iso_code}`}
            </span>
          ),
          value: ele.id,
          iso_code: ele.iso_code,
          country_code: ele.country_code,
        }))
      : []
  );
  const bnkDetails = useSelector((state) => state?.customers?.all?.data?.data);
  const BeneficiaryBankDetails = useSelector((state) =>
    state?.customers?.all?.data?.data?.map((ele) => ({
      ...ele,
      label:
        ele?.beneficiary_name + "-" + ele?.bank_accounts?.[0]?.account_number,
      value: ele?.bank_accounts?.[0]?.id,
    }))
  );

  const onSubmit = (data) => {
    onSub(data);
    next();
  };

  const addressObj = acc?.billing_addresses?.[0];
  const method = watch("method");

  React.useEffect(() => {
    if (mainData) {
      setValue("recipient", mainData?.recipient);
      setValue("method", mainData?.method);
      setValue("currency", mainData?.currency);
    }
  }, [mainData]);

  const setRecipientDetails = (val) => {
    const benAcc = BeneficiaryBankDetails?.find(
      (ele) => ele?.bank_accounts?.[0]?.id === val
    );
    setDBAcc(
      BeneficiaryBankDetails?.find((ele) => ele?.bank_accounts?.[0]?.id === val)
    );
    const method = p_types?.find(
      (ele) => ele.value === benAcc?.account_transfer_mode_type
    )?.value;
    setValue(
      "method",
      p_types?.find((ele) => ele.value === benAcc?.account_transfer_mode_type)
        ?.value
    );
    if (method) {
      setError("method", false);
    }
    const curr = currenciesRes?.find(
      (ele) => ele.country_code === benAcc?.country_code
    );
    setValue("currency", curr?.value);
    setValue("iso_code", curr?.iso_code ? curr?.iso_code : "USD");
  };

  useEffect(() => {
    if (recpientId) {
      const benAcc = bnkDetails?.find((ele) => ele?.id === recpientId);
      if (benAcc) {
        setValue("recipient", benAcc?.bank_accounts?.[0]?.id);
        setRecipientDetails(benAcc?.bank_accounts?.[0]?.id);
        setRecipientId("");
      }
    }
  }, [recpientId, bnkDetails]);
  const recp = watch("recipient");
  return (
    <>
      <CreateManageBeneficiary
        isVisible={openAddNew}
        onClose={() => {
          setOpenAddNew(false);
        }}
        createSet={(data) => {
          setRecipientId(data?.id ? data?.id : data);
        }}
      />
      <form className="w40 ml3" onSubmit={handleSubmit(onSubmit)}>
        <div className="pp-form-item">
          <FormSelect
            inline
            label={"Recipient"}
            placeholder={"Select Recipient"}
            control={control}
            options={BeneficiaryBankDetails}
            required={true}
            {...register("recipient", {
              required: "Please select the recipient.",
              onChange: (e) => {
                setRecipientDetails(e.target.value);
              },
            })}
            errors={errors}
            hideCreateOption={true}
          />
          <span
            className="edit-link"
            onClick={() => {
              setOpenAddNew(true);
            }}
          >
            <img src={u_edit} width={18} /> Add Recipient
          </span>
        </div>
        {recp && (
          <div className="pp-form-item">
            <FormSelect
              inline
              label={"Transfer Method"}
              control={control}
              disabled={true}
              options={p_types}
              {...register("method", {
                required: "Please select the method.",
              })}
              errors={errors}
              hideCreateOption={true}
              placeholder={"Select Transfer Method"}
              noDD={true}
            />
            {/* <p>1 business day</p> */}
          </div>
        )}

        {method?.includes("SWIFT") ? (
          <div className="pp-form-item">
            <FormSelect
              control={control}
              {...register("currency", {
                required: "Currency is required",
                onChange: (e) => {
                  const curr = currenciesRes?.find(
                    (ele) => ele.value === e.target.value
                  );
                  setValue("iso_code", curr?.iso_code);
                },
              })}
              placeholder="Select Currency"
              errors={errors}
              label={"Currency"}
              required={true}
              hideCreateOption
              options={currenciesRes}
              inline
              filterKey={"iso_code"}
              disabled={true}
            />
          </div>
        ) : null}
        {acc?.id ? (
          <div className="mt3">
            <label>Recipient Bank Details</label>
            <div className="bank-details">
              <div className="nickname">
                <span className="label">Nickname</span>
                <span className="value">{acc?.beneficiary_name}</span>
              </div>
              <div className="legal-name">
                <span className="label">Email</span>
                <span className="value">{acc?.email}</span>
              </div>
              <div className="bank-info">
                <div className="bank-name">
                  <img src={bank_icon} alt="Bank Icon" className="bank-icon" />
                </div>
                <div className="acc-details">
                  <span className="value">
                    {acc?.bank_accounts?.[0]?.branch}
                  </span>
                  <span className="account-details">
                    Account ** {acc?.bank_accounts?.[0]?.account_number}
                  </span>
                  <span className="account-details">
                    {acc?.bank_accounts?.[0]?.routing_code
                      ? `Routing Code ** ${acc?.bank_accounts?.[0]?.routing_code}`
                      : null}
                    {acc?.bank_accounts?.[0]?.swift_code
                      ? `Swift Code ** ${acc?.bank_accounts?.[0]?.swift_code}`
                      : null}
                  </span>
                  <span className="account-details">{`${addressObj?.address}, ${addressObj?.city}, ${addressObj?.state}, ${addressObj?.zipcode}, ${addressObj?.country}`}</span>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <button type="submit" className="send-btn mt3">
          Next
        </button>
      </form>
    </>
  );
};

export default RecipientsForm;
