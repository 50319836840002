import { Input, message, Switch } from "antd";
import React, { useEffect, useRef } from "react";
import { gridApis } from "../../../config/GridApis";
import usePayAxios from "../../../config/useAxios";

const BalanceAlerts = ({ hasWriteAccess }) => {
  const { onCall: getBalAlerts, data } = usePayAxios({
    api: gridApis.getBalanceAlerts,
    method: "get",
  });
  const { onCall: saveAlerts, data: saveRes } = usePayAxios({
    api: gridApis.saveBalanceAlerts,
    method: "post",
  });

  const ref = useRef();
  useEffect(() => {
    getBalAlerts({});
  }, []);

  return (
    <div className="balance-alerts">
      <div className="sub-sec-head mb2">
        <h3>Balance Alerts</h3>
        <p>
          Set a custom threshold for each account and get an email alert when
          the balance falls below it.
        </p>
      </div>
      {Array.isArray(data?.data)
        ? data?.data?.map((ele) => {
            return (
              <div className="alert-row">
                <Switch
                  checked={ele.alert_opted}
                  onChange={(check) => {
                    saveAlerts({
                      data: {
                        account_id: ele.account_id,
                        alert_amount_falls: ele.alert_amount_falls,
                        alert_opted: check,
                      },
                    })
                      .then((res) => {
                        if (res?.error === false) getBalAlerts({});
                      })
                      .catch((error) => {
                        if (error?.response?.data?.error) {
                          message.error(
                            <span className="messageText">
                              {error?.response?.data?.message}
                            </span>
                          );
                        }
                      });
                  }}
                  disabled={!hasWriteAccess}
                />
                <div>
                  <label>{ele?.account_name}</label>
                  {/* <span className="sub">Send when balance falls below:</span> */}
                  <Input
                    placeholder="0"
                    prefix="$"
                    className="alert-input"
                    defaultValue={ele.alert_amount_falls}
                    onChange={(e) => {
                      if (ref.current) {
                        clearTimeout(ref.current);
                      }
                      ref.current = setTimeout(() => {
                        saveAlerts({
                          data: {
                            account_id: ele.account_id,
                            alert_amount_falls: e.target.value,
                            alert_opted: ele.alert_opted,
                          },
                        })
                          .then((res) => {
                            if (res?.error === false) getBalAlerts({});
                          })
                          .catch((error) => {
                            if (error?.response?.data?.error) {
                              message.error(
                                <span className="messageText">
                                  {error?.response?.data?.message}
                                </span>
                              );
                            }
                          });
                        clearTimeout(ref.current);
                      }, 700);
                    }}
                    disabled={!ele.alert_opted || !hasWriteAccess}
                  />
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export const AccountActivity = ({ hasWriteAccess }) => {
  const { onCall: getAlerts, data } = usePayAxios({
    api: gridApis.getUserAlerts,
    method: "get",
  });
  const { onCall: updateAlerts } = usePayAxios({
    api: gridApis.updateUserAlert,
    method: "post",
  });

  const ref = useRef();
  useEffect(() => {
    getAlerts({});
  }, []);

  function mergeAllExceptSubscription(data1) {
    return Object.keys(data1)
      .filter((key) => key !== "subscription")
      .reduce((merged, key) => merged.concat(data1[key]), []);
  }
  const accActivity = data?.data ? mergeAllExceptSubscription(data?.data) : [];
  const subscription = data?.data?.subscription;
  return (
    <div className="balance-alerts">
      <div className="sub-sec-head mb2">
        <h3>Account activity</h3>
        <p>
          Monitor large transactions, troubleshoot payment issues, and more.
        </p>
      </div>
      {/* Alert Rows */}
      {accActivity?.map((itm) => (
        <div className="alert-row">
          <Switch
            checked={itm.is_enabled}
            onChange={(check) => {
              updateAlerts({
                data: {
                  id: itm.id,
                  threshold_amount: itm.threshold_amount,
                  is_enabled: check,
                },
              })
                .then((res) => {
                  if (res?.error === false) getAlerts({});
                })
                .catch((error) => {
                  if (error?.response?.data?.error) {
                    message.error(
                      <span className="messageText">
                        {error?.response?.data?.message}
                      </span>
                    );
                  }
                });
            }}
            disabled={!hasWriteAccess}
          />
          <div>
            <label>{itm.alert_name}</label>
            <span className="sub">{itm.description}</span>
            {itm.alert_field_type === "number" && (
              <Input
                placeholder="0"
                prefix="$"
                className="alert-input"
                defaultValue={itm.threshold_amount}
                disabled={!itm.is_enabled || !hasWriteAccess}
                onChange={(e) => {
                  if (ref.current) {
                    clearTimeout(ref.current);
                  }
                  ref.current = setTimeout(() => {
                    updateAlerts({
                      data: {
                        id: itm.id,
                        threshold_amount: e.target.value,
                      },
                    })
                      .then((res) => {
                        if (res?.error === false) getAlerts({});
                      })
                      .catch((error) => {
                        if (error?.response?.data?.error) {
                          message.error(
                            <span className="messageText">
                              {error?.response?.data?.message}
                            </span>
                          );
                        }
                      });
                    clearTimeout(ref.current);
                  }, 700);
                }}
              />
            )}
          </div>
        </div>
      ))}
      {/* <div className="sub-sec-head mb2 mt1">
        <h3>Subscription activity</h3>
      </div> */}
      {/* <div className="alert-row">
        <Switch />
        <label>Upcoming subscription renewal</label>
      </div> */}
      {subscription?.map((ele) => {
        return (
          <div className="alert-row">
            <Switch
              checked={ele.is_enabled}
              onChange={(check) => {
                updateAlerts({
                  data: {
                    id: ele.id,
                    is_enabled: check,
                  },
                })
                  .then((res) => {
                    if (res?.error === false) getAlerts({});
                  })
                  .catch((error) => {
                    if (error?.response?.data?.error) {
                      message.error(
                        <span className="messageText">
                          {error?.response?.data?.message}
                        </span>
                      );
                    }
                  });
              }}
              disabled={!hasWriteAccess}
            />
            <div>
              <label>{ele?.alert_name}</label>
              <span className="sub">{ele?.description}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const CreditActivity = () => {
  return (
    <div className="balance-alerts">
      <div className="sub-sec-head mb2">
        <h3>Credit</h3>
        <p>
          Track limit changes and make sure IO payments are processing on time.
        </p>
      </div>
      {/* Alert Rows */}
      <div className="alert-row">
        <Switch defaultChecked />
        <div>
          <label>Credit balance exceeds a custom percentage</label>
        </div>
      </div>
      <div className="alert-row">
        <Switch defaultChecked />
        <div>
          <label>Credit limit increases or decreases</label>
        </div>
      </div>
      <div className="alert-row">
        <Switch defaultChecked />
        <div>
          <label>Early payment warning</label>
          <span className="sub">
            Sent 3 days before your next scheduled IO balance payment.
          </span>
        </div>
      </div>
      <div className="alert-row">
        <Switch defaultChecked />
        <div>
          <label>Payment processed due to low available limit</label>
        </div>
      </div>
      <div className="alert-row">
        <Switch defaultChecked />
        <div>
          <label>Backup account was used to process payment</label>
        </div>
      </div>
    </div>
  );
};

export const CardActivity = () => {
  return (
    <div className="balance-alerts">
      <div className="sub-sec-head mb2">
        <h3>My Cards</h3>
        {/* <p>
          Track limit changes and make sure IO payments are processing on time.
        </p> */}
      </div>
      {/* Alert Rows */}
      <div className="alert-row">
        <Switch defaultChecked />
        <div>
          <label>Card transaction is declined</label>
        </div>
      </div>
      <div className="alert-row">
        <Switch defaultChecked />
        <div>
          <label>Card is used outside of the United States</label>
        </div>
      </div>
      <div className="alert-row">
        <Switch defaultChecked />
        <div>
          <label>Card is used for an online purchase</label>
        </div>
      </div>
    </div>
  );
};

export default BalanceAlerts;
