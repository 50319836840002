import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { amountFormat } from "../../utils";
import TableComponent from "../Common/TableComp/TableComp";

const ChildAccounts = ({ data, loading }) => {
  const [visibleBalances, setVisibleBalances] = useState({});
  const history = useHistory();

  const toggleBalanceVisibility = (id) => {
    setVisibleBalances((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const columns = [
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Account Type",
      dataIndex: "account_type",
      key: "account_type",
    },
    {
      title: "Currency Code",
      dataIndex: "currency_code",
      key: "currency_code",
    },
    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: "Balance",
      key: "balance",
      render: (_, record) => {
        const isVisible = visibleBalances[record.id];
        return (
          <div>
            {isVisible ? (
              `$ ${amountFormat(record?.balance?.balance)}`
            ) : (
              <span>••••••</span>
            )}
            <Button
              type="text"
              icon={isVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                toggleBalanceVisibility(record.id);
              }}
            />
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => new Date(date).toLocaleString(),
    },
  ];

  return (
    <TableComponent
      columns={columns}
      hasCheckbox={false}
      loading={loading}
      data={Array.isArray(data) ? data : []}
      scroll={{
        x: "max-content",
      }}
      handleRowClick={(_, rec) => {
        history.push("/account/detail", {
          account: rec,
          child: true,
        });
      }}
    />
  );
};

export default ChildAccounts;
