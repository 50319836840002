import { CaretRightOutlined } from "@ant-design/icons";
import { Col, Collapse, Input, Row, Select, Switch } from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { cardEnalbeLimit, set_card_policy } from "../../actions/cards/cards";
import close from "../../assets/icons/close.png";
import EditIcon from "../../assets/icons/edit.png";
import save from "../../assets/icons/save.png";
import BaseCurrency from "../Common/BaseCurrency";
import "./card.css";
import CardLimits from "./CardLimits";

const schema = yup.object().shape({
  transaction_type: yup.string().required(),
  amount: yup.string().required(),
  frequency: yup.string().required(),
});
const { Panel } = Collapse;
const { Option } = Select;

const CardSettingsAccordion = ({
  card_id,
  channels,
  card_ref_number,
  cardType,
}) => {
  const isAddValue = cardType ? true : false;
  const [isAdd, setIsAdd] = useState(false);

  const setCardLimit = useSelector((state) => state.cards.set_card_policy);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      card_id,
      card_ref_number,
      transaction_type: "",
      amount: "",
      frequency: "",
    },
    validationSchema: schema,
    onSubmit: (vals, { setSubmitting }) => {
      dispatch(set_card_policy({ card: vals }));
      setSubmitting(false);
    },
  });

  const [txnEnable, setTxnEnable] = useState({
    ECOM: false,
    ATM: false,
    POS: false,
    CTLS: false,
  });

  useEffect(() => {
    if (channels) {
      setTxnEnable({
        ECOM: channels?.includes("ECOM"),
        ATM: channels?.includes("ATM"),
        POS: channels?.includes("POS"),
        CTLS: channels?.includes("CTLS"),
      });
    }
  }, [channels]);

  useEffect(() => {
    if (setCardLimit?.success) {
      setIsAdd(false);
      formik.resetForm();
      formik.setValues({
        card_id,
        card_ref_number,
        transaction_type: "",
        amount: "",
        frequency: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCardLimit]);

  const limits = {
    domesticPOS: "$10,000 per Day",
    internationalPOS: "$10,000 per Transaction",
    domesticATM: "$100 per Day",
    internationalATM: "$22,000 per Transaction",
    domesticOnline: "$50,000 per Month",
    internationalOnline: "$50,000 per Transaction",
  };

  return (
    <div
      className="pt1 pb1 pl1"
      style={{
        position: "relative",
      }}
    >
      {cardType === "card_setting_page" ? (
        ""
      ) : (
        <>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            expandIconPosition={"right"}
            className="site-collapse-custom-collapse"
          >
            <Panel
              header={`Card Usage Controls ( if any )`}
              className="accordion-heading"
            >
              <div className="card-usage">
                <div className="item">
                  <div>
                    <span>POS</span>
                    <span></span>
                  </div>
                  <Switch
                    className="switch"
                    checked={txnEnable["POS"]}
                    onChange={(val) => {
                      setTxnEnable((prev) => ({
                        ...prev,
                        POS: val,
                      }));
                      dispatch(
                        cardEnalbeLimit({
                          card: {
                            card_id,
                            transaction_type: "POS",
                            is_enabled: val,
                          },
                        })
                      );
                    }}
                  />
                </div>
                <div className="item">
                  <div>
                    <span>ATM Withdrawal</span>
                    <span></span>
                  </div>
                  <Switch
                    className="switch"
                    checked={txnEnable["ATM"]}
                    onChange={(val) => {
                      setTxnEnable((prev) => ({
                        ...prev,
                        ATM: val,
                      }));
                      dispatch(
                        cardEnalbeLimit({
                          card: {
                            card_id,
                            transaction_type: "ATM",
                            is_enabled: val,
                          },
                        })
                      );
                    }}
                  />
                </div>
                <div className="item">
                  <div>
                    <span>Online Transactions</span>
                    <span></span>
                  </div>
                  <Switch
                    className="switch"
                    checked={txnEnable["ECOM"]}
                    onChange={(val) => {
                      setTxnEnable((prev) => ({
                        ...prev,
                        ECOM: val,
                      }));
                      dispatch(
                        cardEnalbeLimit({
                          card: {
                            card_id,
                            transaction_type: "ECOM",
                            is_enabled: val,
                          },
                        })
                      );
                    }}
                  />
                </div>
                <div className="item">
                  <div>
                    <span>ContactLess</span>
                    <span></span>
                  </div>
                  <Switch
                    className="switch"
                    checked={txnEnable["CTLS"]}
                    onChange={(val) => {
                      setTxnEnable((prev) => ({
                        ...prev,
                        CTLS: val,
                      }));
                      dispatch(
                        cardEnalbeLimit({
                          card: {
                            card_id,
                            transaction_type: "CTLS",
                            is_enabled: val,
                          },
                        })
                      );
                    }}
                  />
                </div>
              </div>
            </Panel>
          </Collapse>
        </>
      )}
      {isAdd ? (
        <CardLimits saveLimit={() => setIsAdd(false)} />
      ) : (
        <>
          <button
            className="card-secondary-button"
            htmlType="submit"
            style={{ right: 0, top: "-4rem", position: "absolute" }}
            onClick={() => {
              setIsAdd(true);
            }}
          >
            Edit Limits
          </button>
          <Row gutter={[16]} className="other-info mb2">
            <Col span={6}>
              <p>
                <label>Domestic POS Transactions: </label>
              </p>
              <p>{limits.domesticPOS}</p>
            </Col>
            <Col span={6}>
              <p>
                <label>Domestic ATM Withdrawal: </label>
              </p>
              <p>{limits.domesticATM}</p>
            </Col>
            <Col span={6}>
              <p>
                <label>Domestic Online Transactions: </label>
              </p>
              <p>{limits.domesticOnline}</p>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={6}>
              <p>
                <label>International POS Transactions: </label>
              </p>
              <p>{limits.internationalPOS}</p>
            </Col>
            <Col span={6}>
              <p>
                <label>International Online Transactions: </label>
              </p>
              <p>{limits.internationalOnline}</p>
            </Col>
            <Col span={6}>
              <p>
                <label>International ATM Withdrawal: </label>
              </p>
              <p>{limits.internationalATM}</p>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

const SpendLimits = ({
  current,
  max,
  type,
  transaction_type,
  onEdit,
  isEdit,
  formik,
  onSubmit,
  is_admin,
  isAdd,
  availableChannels,
  closeAdd,
  hasWriteAccess,
}) => {
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="alignItemsToFlexStart">
        <div>
          <div className="inputDivTag">
            <label className="poppinsNormalFont twelveFontSize spend-limits">
              Spend Limits
            </label>
            <Input
              prefix={<BaseCurrency />}
              className="inputTag"
              name="amount"
              onChange={formik.handleChange}
              disabled={isAdd ? false : !isEdit}
              value={isEdit || isAdd ? formik.values.amount : current}
            />
          </div>
          {isAdd && formik.touched.amount && formik.errors.amount && (
            <div className="errorMsg">{formik.errors.amount}</div>
          )}
        </div>
        <div>
          <div className="inputDivTag">
            <label className="poppinsNormalFont twelveFontSize">Per</label>
            {isAdd ? (
              <Select
                placeholder="Select "
                onChange={(val) => formik.setFieldValue(`frequency`, val)}
                value={formik.values.frequency}
              >
                {!availableChannels?.find(
                  (ele) =>
                    ele.type.includes("DAILY") &&
                    ele.channel === formik.values.transaction_type
                ) && <Option value="DAILY">Daily Limit</Option>}
                {!availableChannels?.find(
                  (ele) =>
                    ele.type.includes("MONTHLY") &&
                    ele.channel === formik.values.transaction_type
                ) && <Option value="MONTHLY">Monthly Limit</Option>}
                {!availableChannels?.find(
                  (ele) =>
                    ele.type.includes("ANNUALLY") &&
                    ele.channel === formik.values.transaction_type
                ) && <Option value="ANNUALLY">Yearly Limit</Option>}
              </Select>
            ) : (
              <Input value={type} className="inputTag" disabled />
            )}
          </div>
          {isAdd && formik.touched.frequency && formik.errors.frequency && (
            <div className="errorMsg">{formik.errors.frequency}</div>
          )}
        </div>
        <div>
          <div className="inputDivTag">
            <label className="poppinsNormalFont twelveFontSize">On</label>
            {isAdd ? (
              <Select
                placeholder="Select "
                onChange={(val) =>
                  formik.setFieldValue(`transaction_type`, val)
                }
                value={formik.values.transaction_type}
              >
                {!availableChannels?.find(
                  (ele) =>
                    ele.type.includes(formik.values.frequency) &&
                    ele.channel === "ATM"
                ) && <Option value="ATM">ATM</Option>}
                {!availableChannels?.find(
                  (ele) =>
                    ele.type.includes(formik.values.frequency) &&
                    ele.channel === "POS"
                ) && <Option value="POS">POS</Option>}
                {!availableChannels?.find(
                  (ele) =>
                    ele.type.includes(formik.values.frequency) &&
                    ele.channel === "ECOM"
                ) && <Option value="ECOM">Online</Option>}
                {!availableChannels?.find(
                  (ele) =>
                    ele.type.includes(formik.values.frequency) &&
                    ele.channel === "CTLS"
                ) && <Option value="CTLS">ContactLess</Option>}
              </Select>
            ) : (
              <Input value={transaction_type} className="inputTag" disabled />
            )}
          </div>
          {isAdd &&
            formik.touched.transaction_type &&
            formik.errors.transaction_type && (
              <div className="errorMsg">{formik.errors.transaction_type}</div>
            )}
        </div>
        {!isAdd && (
          <div className="inputDivTag">
            <label className="poppinsNormalFont twelveFontSize max-limits">
              Max Limit
            </label>
            <Input
              prefix={<BaseCurrency />}
              value={max}
              className="inputTag"
              disabled
            />
          </div>
        )}
        {is_admin && (
          <div className="actions">
            {isEdit || isAdd ? (
              <>
                <button
                  disabled={!hasWriteAccess}
                  type={isEdit ? "button" : "submit"}
                  className="ml1"
                  onClick={() => {
                    if (isEdit) onSubmit();
                  }}
                >
                  <img src={save} alt="" width={16} />
                </button>
                <button
                  disabled={!hasWriteAccess}
                  type="button"
                  className="ml1"
                  onClick={() => {
                    if (isAdd) closeAdd();
                    else {
                      onEdit(true);
                    }
                  }}
                >
                  <img src={close} alt="" width={16} />
                </button>
              </>
            ) : (
              <button
                disabled={!hasWriteAccess}
                className="ml1"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit();
                }}
              >
                <img src={EditIcon} alt={"logo"} width={25} />
              </button>
            )}
          </div>
        )}
      </div>
    </form>
  );
};

export default CardSettingsAccordion;
