import React, { lazy, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./assets/css/Purchases.css";
import Login from "./components/Auth/Login";
import Loader from "./components/Loader";
import { hasAccess, isPaymentsWrite, RouteConstants } from "./utils";
// import Footer from "./components/Footer/Footer";
import { ConfigProvider, Layout, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import LeftNavProvider from "./LeftNavProvider.js";
import { Logout } from "./actions/auth";
import no_mob from "./assets/images/no_mob.png";
import AccountDetail from "./components/Account/AccountDetail.js";
import AccountTransaction from "./components/Account/AccountTransaction";
import PrimaryAcc from "./components/Account/PrimaryAcc";
import VirtualAcc from "./components/Account/VirtualAcc";
import Banks from "./components/Accounting/Banks";
import BanksCards from "./components/Accounting/Cards";
import Summary from "./components/Accounting/Summary";
import Cards from "./components/Cards/Card";
import CardDetail from "./components/Common/CardDetail";
import EmailAction from "./components/Common/EmailAction";
import IdleLogout from "./components/Common/IdleTimer.js";
import SendEmail from "./components/Common/SendEmail";
import HeaderFooterWrap from "./components/HeaderFooterWrap.js";
import BusinessMainKYC from "./components/OnBoarding/Auth/BusinessKYC/BusinessMainKYC";
import KYCScreens from "./components/OnBoarding/Auth/KYCScreen";
import OnBoardLeftNavigation from "./components/OnBoarding/Auth/OnBoardLeftNavigation/OnBoardLeftNavigation";
import NewPaymentDetail from "./components/Payments/NewPaymentDetails";
import PaymentOpts from "./components/Payments/PaymentOptions/PayOptions.js";
import SendMoney from "./components/Payments/SendMoney/SendMoney.js";
import BankingRedirect from "./components/Redirect";
import Organisation from "./components/Settings/Organisation/Organisation";
import Portal from "./components/Settings/Organisation/Portal";
import Settings from "./components/Settings/Setting";
import AddBulkUsers from "./components/Settings/UsersControls/Users/AddBulkUsers";
import BenificiaryTransfers from "./components/Transfers/BankTransfers/BenificiaryTransfers";
import CompanyTransfers from "./components/Transfers/BankTransfers/CompanyTransfers";
import History from "./components/Transfers/BankTransfers/History";
import BulkTransfers from "./components/Transfers/BulkTransfers";
import BulkHistory from "./components/Transfers/BulkTransfers/BulkHistory";
import BulkHistoryDetails from "./components/Transfers/BulkTransfers/BulkHistoryDetail";
import UploadPayments from "./components/Transfers/BulkTransfers/UploadPayments";
import ManageBeneficiary from "./components/Transfers/ManageBeneficiary";
import PaymentLinks from "./components/Transfers/PaymentLinks";
import TransferLog from "./components/Transfers/TransferLog";
import ErrorModal from "./config/ErrorModal";
import history from "./history";
import i18n from "./i18n";
import { ar } from "./languages/arabic";
import { en } from "./languages/english";
const NewDashboard = lazy(() => import("./components/Dashboard2/Dashboard"));

const Content = Layout.Content;

export const OnBoardingHeaderFooterWrap = (props) => {
  return (
    <>
      <Layout
        className="flex-row onBoard-layout"
        style={{
          minHeight: "100vh",
          background: "#FFFFFF",
        }}
      >
        <OnBoardLeftNavigation />
        <Layout>
          <Content>{props.children}</Content>
        </Layout>
      </Layout>
    </>
  );
};

function PrivateRoute({ children, path, exact }) {
  return (
    <Route
      path={path}
      exact={exact}
      render={() => {
        return localStorage.getItem("token") ? children : <Redirect to="/" />;
      }}
    />
  );
}

function App() {
  const [isLoading, setIsLoading] = React.useState(true);
  const logoutResponse = useSelector((state) => state.auth.logout || {});
  const topup = useSelector((state) => state.cards.topup);
  const uploadData = useSelector((state) => state?.cards?.uploadBulk);
  const cards_loading = useSelector(
    (state) => state.analytics.analytics_cards?.loading
  );
  const { t } = useTranslation();
  const appLoading = useSelector(
    (state) => state?.auth?.localVariables?.loading
  );
  const width = window.screen.width;
  const benLoading = useSelector((state) => state.customers?.create?.loading);
  const payLoading = useSelector(
    (state) => state.z_accs?.initialize_payment?.loading
  );

  const estimates_loading = useSelector(
    (state) => state.cards?.analytics_estimates?.loading
  );
  const blockCardResponseLoading = useSelector(
    (state) => state.cards.block?.loading
  );
  const replacementResponseLoading = useSelector(
    (state) => state.cards.replacement?.loading
  );
  const card_enalbe_loading = useSelector(
    (state) => state.cards.card_enable?.loading
  );
  const language = useSelector((state) => state.auth.localVariables?.language);
  const corporate_budget_response = useSelector(
    (state) => state.cards.corporate_budget?.data?.response
  );
  const lastRef = useRef();
  const dispatch = useDispatch();

  React.useEffect(() => {
    hideLoader();
  }, []);

  const langMap = {
    en: en,
    ar: ar,
  };

  React.useEffect(() => {
    if (language && langMap[language]) {
      i18n.addResourceBundle(language, "translation", langMap[language]);
      i18n.changeLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [language]);
  React.useEffect(() => {
    setIsLoading(
      topup?.loading ||
        uploadData?.loading ||
        cards_loading ||
        estimates_loading ||
        card_enalbe_loading ||
        blockCardResponseLoading ||
        replacementResponseLoading ||
        appLoading ||
        payLoading ||
        benLoading
    );
  }, [
    topup?.loading,
    uploadData?.loading,
    cards_loading,
    estimates_loading,
    card_enalbe_loading,
    blockCardResponseLoading,
    replacementResponseLoading,
    appLoading,
    benLoading,
    payLoading,
  ]);

  const hideLoader = () => {
    const proc = new Promise((resolve) => {
      setTimeout(() => resolve(), 50);
    });
    proc.then(() => setIsLoading(false));
  };

  React.useEffect(() => {
    if (logoutResponse.success) {
      if (logoutResponse.data.message) {
        if (lastRef.current) {
          clearTimeout(lastRef.current);
        }
        lastRef.current = setTimeout(() => {
          message.success(
            <span className="messageText">{logoutResponse.data.message}</span>
          );
        }, 200);
      }
      dispatch({
        type: Logout.RESET,
      });
    } else if (logoutResponse.success === false) {
      dispatch({
        type: Logout.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutResponse]);

  React.useEffect(() => {
    if (corporate_budget_response?.success) {
    } else {
      if (corporate_budget_response?.message) {
        message.error(
          <span className="messageText">{logoutResponse.message}</span>
        );
        localStorage.clear();
        history.push(RouteConstants.ROOT);
        window.location.reload(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [corporate_budget_response]);

  return (
    <ConfigProvider direction={language === "en" ? "ltr" : "rtl"}>
      <LeftNavProvider>
        <React.Suspense fallback={<Loader />}>
          {width < 1024 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                backgroundColor: "#f9f9f9",
                padding: "20px",
              }}
            >
              <img
                src={no_mob} // Replace with your image URL
                alt="Not Compatible"
                style={{ width: "200px", marginBottom: "20px" }}
              />
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#212121",
                }}
              >
                Mobile Experience Inprogress
              </div>
              <p
                style={{
                  marginTop: "10px",
                  color: "#21212180",
                  maxWidth: "70%",
                  textAlign: "center",
                }}
              >
                Currently, the platform is only accessible via laptops and
                desktops. We are working to bring mobile support soon. Stay
                tuned for updates!
              </p>
            </div>
          ) : (
            <div className={language === "en" ? "ltr" : "rtl"}>
              <div className="App">
                {isLoading ? <Loader /> : null}
                <Router history={history}>
                  <Switch>
                    <Route path="/" component={Login} exact />
                    <Route path="/corporate-login" component={Login} exact />
                    <Route path="/request-demo" component={Login} />
                    <Route path="/reset-password" component={Login} />
                    <Route path="/two-step-verify" component={Login} />
                    <Route path="/set-password" component={Login} />
                    <Route path="/forgot-password/" component={Login} />
                    <Route path="/confirm_email/" component={Login} />
                    <Route path="/sign-up/" component={Login} />
                    {/* <PrivateRoute path="/new-dashboard">
            <NewDashboard />
          </PrivateRoute> */}

                    <PrivateRoute path="/organisation">
                      <Organisation />
                    </PrivateRoute>
                    <PrivateRoute path="/portal">
                      <Portal />
                    </PrivateRoute>
                    <PrivateRoute path="/dashboard/">
                      <HeaderFooterWrap>
                        {/* {is_admin ? <NewDashboard /> : <Dashboard />} */}
                        <NewDashboard />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute path="/settings">
                      <HeaderFooterWrap>
                        <Settings />
                      </HeaderFooterWrap>
                    </PrivateRoute>

                    <PrivateRoute
                      exact={true}
                      path="/transfers/bulk/detailpage"
                    >
                      <HeaderFooterWrap>
                        <BulkHistoryDetails />
                      </HeaderFooterWrap>
                    </PrivateRoute>

                    <PrivateRoute exact={true} path="/transfers/bulk/approvals">
                      <HeaderFooterWrap>
                        <BulkHistory
                          hasWriteAccess={hasAccess(
                            "bulk_transfers_history",
                            "write"
                          )}
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute exact path="/transfers/bulk/my-bulk-payments">
                      <HeaderFooterWrap>
                        <BulkHistory
                          hasWriteAccess={hasAccess(
                            "bulk_transfers_history",
                            "write"
                          )}
                          user={true}
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute exact path="/transfers/bulk/my-bulk-payments">
                      <HeaderFooterWrap>
                        <BulkHistory
                          hasWriteAccess={hasAccess(
                            "bulk_transfers_history",
                            "write"
                          )}
                          user={true}
                          scheduled
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute path="/transfers/send_money">
                      <HeaderFooterWrap>
                        <SendMoney hasWriteAccess={true} />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute
                      exact={true}
                      path="/transfers/bulkpayment/uploadpaymentlinks"
                    >
                      <HeaderFooterWrap>
                        <UploadPayments
                          type={"bulk_payment"}
                          hasWriteAccess={hasAccess(
                            "bulk_upload_payment_links",
                            "write"
                          )}
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute
                      exact={true}
                      path="/transfers/bulkpayment/history"
                    >
                      <HeaderFooterWrap>
                        <BulkHistory
                          type={"bulk_payment_history"}
                          hasWriteAccess={hasAccess(
                            "bulk_payment_history",
                            "write"
                          )}
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>

                    <PrivateRoute
                      exact={true}
                      path="/transfers/bulkpayment/detailpage"
                    >
                      <HeaderFooterWrap>
                        <BulkHistoryDetails type={"bulk_payment_detail"} />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path="/transfers/payment_links">
                      <HeaderFooterWrap>
                        <PaymentLinks
                          hasWriteAccess={hasAccess("payment_links", "write")}
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute
                      exact={true}
                      path="/transfers/manage_beneficiary"
                    >
                      <HeaderFooterWrap>
                        <ManageBeneficiary
                          hasWriteAccess={hasAccess(
                            "manage_recipients",
                            "write"
                          )}
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path="/transfers/bank/">
                      <HeaderFooterWrap>
                        <CompanyTransfers
                          hasWriteAccess={hasAccess(
                            "company_accounts",
                            "write"
                          )}
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute
                      exact={true}
                      path="/transfers/beneficiary/approvals"
                    >
                      <HeaderFooterWrap>
                        <BenificiaryTransfers
                          hasWriteAccess={isPaymentsWrite()}
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute
                      exact={true}
                      path="/transfers/beneficiary/payments"
                    >
                      <HeaderFooterWrap>
                        <BenificiaryTransfers
                          hasWriteAccess={isPaymentsWrite()}
                          sendMoney={true}
                          user={true}
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute
                      exact={true}
                      path="/transfers/beneficiary/scheduled"
                    >
                      <HeaderFooterWrap>
                        <BenificiaryTransfers
                          hasWriteAccess={isPaymentsWrite()}
                          sendMoney={true}
                          user={true}
                          scheduled={true}
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path="/payment-details/:id">
                      <HeaderFooterWrap>
                        <NewPaymentDetail
                          hasWriteAccess={hasAccess(
                            "beneficiary_accounts",
                            "write"
                          )}
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path="/transfers/bank/history">
                      <HeaderFooterWrap>
                        <History
                          hasWriteAccess={hasAccess("history", "write")}
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute
                      exact={true}
                      path="/cards/transfers/transfers-log/"
                    >
                      <HeaderFooterWrap name="Transfers">
                        <TransferLog
                          hasWriteAccess={hasAccess("transfer_logs", "write")}
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute path="/transfers/transfers-reports/">
                      <HeaderFooterWrap role="transfers" name="Transfers">
                        <BulkTransfers />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    {/* <PrivateRoute exact={true} path="/account">
                <HeaderFooterWrap role="company_master_account" name="Account">
                  <Account
                    hasWriteAccess={hasAccess(
                      "company_master_account",
                      "write"
                    )}
                  />
                </HeaderFooterWrap>
              </PrivateRoute> */}
                    <PrivateRoute exact={true} path="/account">
                      <HeaderFooterWrap role="bank_accounts">
                        <PrimaryAcc
                          hasWriteAccess={hasAccess(
                            "internal_fund_transfers",
                            "write"
                          )}
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path="/account/detail">
                      <HeaderFooterWrap role="bank_accounts">
                        <AccountDetail
                          hasWriteAccess={hasAccess(
                            "internal_fund_transfers",
                            "write"
                          )}
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path="/account/accs/virtual">
                      <HeaderFooterWrap>
                        <VirtualAcc />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute path="/upload-bulk-users/">
                      <HeaderFooterWrap>
                        <AddBulkUsers />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute path="/upload-bulk-beneficiaries/">
                      <HeaderFooterWrap>
                        <AddBulkUsers isBeneficiary={true} />
                      </HeaderFooterWrap>
                    </PrivateRoute>

                    <PrivateRoute path="/transactions/">
                      <HeaderFooterWrap
                        role="account_transactions"
                        name="Account"
                      >
                        <AccountTransaction
                          hasWriteAccess={hasAccess(
                            "account_transactions",
                            "read"
                          )}
                          txns={true}
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>

                    <PrivateRoute exact={true} path="/sendemail">
                      <HeaderFooterWrap>
                        <SendEmail />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path="/accounting/summary">
                      <HeaderFooterWrap>
                        <Summary />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path="/statements">
                      <HeaderFooterWrap>
                        <Banks hasWriteAccess={hasAccess("banks", "read")} />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path="/accounting/cards">
                      <HeaderFooterWrap>
                        <BanksCards />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute path="/card-details/">
                      <HeaderFooterWrap role="cards" name="Cards">
                        <CardDetail
                          hasWriteAccess={hasAccess("all_cards", "write")}
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>
                    <PrivateRoute path="/cards/debit-card/">
                      <HeaderFooterWrap role="cards" name="Cards">
                        <Cards
                          hasWriteAccess={hasAccess("all_cards", "write")}
                          debit={true}
                        />
                      </HeaderFooterWrap>
                    </PrivateRoute>

                    {/* customer Protal routes */}

                    {/* onboarding screens */}
                    <PrivateRoute exact={true} path="/onboard/kyc">
                      {/* <HeaderFooterWrap> */}
                      <KYCScreens />
                      {/* </HeaderFooterWrap> */}
                    </PrivateRoute>

                    <PrivateRoute exact={true} path="/onboard/businesskyc">
                      <OnBoardingHeaderFooterWrap>
                        <BusinessMainKYC />
                      </OnBoardingHeaderFooterWrap>
                    </PrivateRoute>

                    <Route path={"/consent"} component={BankingRedirect} />

                    {/* sales and purchases modules */}
                    <Route
                      path={"/email-approve"}
                      render={() => <EmailAction type="approve" />}
                    />
                    <Route
                      path={"/email-reject"}
                      render={() => <EmailAction type="reject" />}
                    />
                    <Route
                      path={"/payments-select/:id"}
                      render={() => <PaymentOpts />}
                    />
                  </Switch>
                </Router>
                <ErrorModal />
                <ToastContainer />
                <IdleLogout />
                <div className="shimmer"></div>
              </div>
            </div>
          )}
        </React.Suspense>
      </LeftNavProvider>
    </ConfigProvider>
  );
}

export default App;
