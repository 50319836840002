import React from "react";

const AccountList = () => {
  const accounts = [
    {
      name: "Avm Technologies Pvt. Ltd.",
      account: "xxxx 8787",
      role: "Admin",
      logo: "https://picsum.photos/40?random=1",
    },
    {
      name: "Global Tech Solutions",
      account: "xxxx 4556",
      logo: "https://picsum.photos/40?random=2",
    },
    {
      name: "Green Leaf Enterprises",
      account: "xxxx 2444",
      logo: "https://picsum.photos/40?random=3",
    },
    {
      name: "Apex Innovations Ltd.",
      account: "xxxx 2948",
      logo: "https://picsum.photos/40?random=4",
    },
    {
      name: "Blue Horizon Industries",
      account: "xxxx 0949",
      logo: "https://picsum.photos/40?random=5",
    },
  ];

  return (
    <div className="modal-body">
      <h4>Switch accounts</h4>
      <ul className="account-list">
        {accounts.map((account, index) => (
          <li key={index} className="account-item">
            <img
              src={account.logo}
              alt={`${account.name} logo`}
              className="account-logo"
            />
            <div className="account-details">
              <span className="account-name">{account.name} - </span>
              <span className="account-info">{account.account}</span>
              {account.role && (
                <span className="account-role">{account.role}</span>
              )}
            </div>
          </li>
        ))}
      </ul>
      <div className="account-actions">
        <span className="action-link">Apply for a new account</span>
        <span className="action-link">Link an existing account</span>
      </div>
    </div>
  );
};

export default AccountList;
